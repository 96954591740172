import React, { Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { CostTable, WysWyg } from '../../components';
import { CostTable as CostTableView } from '../../ObjectView/components';
import {
  Box,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { NewLinkedObject } from '../../components';
import { Popup } from 'components';
import classNames from 'classnames';
import {
  EO_IMPACT as impactList,
  KOE_EO_SETTLEMENT as settlementList
} from '../data.js';
import { inputStyles, useStyles } from '../../styles';
import { isSentStatus } from '../statusProcessor';
import {
  ID,
  IMPACT,
  IMPACT_PRICE,
  IMPACT_SCHEDULE,
  SETTLEMENT,
  STATUS_DRAFT,
  STATUS_INFO_REQUESTED,
  STATUS_ESTIMATE_SUBMITTED,
  REVISION_VALUES,
  SEMICOLON,
  VALUES,
  STATUS_READY_FOR_APPROVAL
} from 'common/constants';
import { valueInArray } from 'common/helper';
import _ from 'lodash';

const Eo = props => {
  const classes = useStyles();
  const {
    clearTimer,
    createdBy,
    disableEdit,
    displayObjectView,
    formState,
    handleCb,
    handleChange,
    handleEditorChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    isReloading,
    isRevision,
    objectTypes,
    projectId,
    recipientName,
    setFormState,
    setHasPopup,
    suggestions
  } = props;

  const valuesField = useMemo(() => (isRevision ? REVISION_VALUES : VALUES), [
    isRevision
  ]);
  const {
    discipline_type_id,
    id,
    linked_objects = [],
    metadata,
    status
  } = formState[valuesField];

  const {
    comment,
    description,
    impact = [],
    impact_description,
    impact_price_description,
    impact_total_schedule_days,
    settlement = [],
    total_cost_nok
  } = metadata;
  const vals = {
    impact: [...impact],
    settlement: [...settlement]
  };

  const exclusionList = useMemo(() => (id ? [{ id: id }] : []), [id]);
  const settlementKoe = useMemo(() => {
    return linked_objects.filter(lo => lo.is_settlement);
  }, [linked_objects]);

  const statusName = useMemo(() => status?.name || STATUS_DRAFT, [status]);
  const isSenderEdit = useMemo(() => {
    return (
      !isSentStatus(statusName) ||
      (isCreatorOrg &&
        valueInArray(
          [STATUS_INFO_REQUESTED, STATUS_ESTIMATE_SUBMITTED],
          statusName
        ))
    );
  }, [isCreatorOrg, statusName]);

  const showComment = useMemo(
    () =>
      !(
        valueInArray([STATUS_DRAFT, STATUS_READY_FOR_APPROVAL], statusName) &&
        formState.selectedRevision <= 0
      ),
    [statusName, formState.selectedRevision]
  );

  // newData is an array of Str8line Objects
  const addSettlementObjects = useCallback(
    (newData = []) => {
      setFormState(formState => {
        let linkedObjects = (
          formState[valuesField].linked_objects || []
        ).filter(lo => !lo.is_settlement);
        linkedObjects = newData
          .map(nd => ({ ...nd, is_settlement: true }))
          .concat(linkedObjects);
        linkedObjects = _.uniqBy(linkedObjects, ID);

        return {
          ...formState,
          [valuesField]: {
            ...formState[valuesField],
            linked_objects: linkedObjects
          }
        };
      });
    },
    [setFormState, valuesField]
  );

  const goToLinkedObject = useCallback(
    (event, id, object_type) => {
      event.preventDefault();
      if (displayObjectView) displayObjectView(id, object_type);
    },
    [displayObjectView]
  );

  return (
    <div>
      <Divider />
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Created by"
                  id="common.CREATED_BY"
                />
              }
              size="small"
              value={createdBy || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} />
          <Grid item md={12} xs={12}>
            <Typography className={classes.boldLabel}>
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
              : *
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <WysWyg
              clearTimer={clearTimer}
              defaultValue={description}
              displayObjectView={displayObjectView}
              error={hasError('description_text')}
              handleMentionItems={handleMentionItems}
              handleParentEditorChange={handleEditorChange}
              isReloading={isReloading}
              key={`description_${id}`}
              mentionedObjs={formState.mentionedObjs}
              mentionedUsers={formState.mentionedUsers}
              name="description"
              objectTypes={objectTypes}
              projectId={projectId}
              readOnly={!isSenderEdit || disableEdit}
              setHasPopup={setHasPopup}
              suggestions={suggestions}
            />
            {hasError('description_text') ? (
              <FormHelperText
                className={classes.errorText}
                error
                id="descriptionHelperText">
                {formState.errors.description_text[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              className={classNames(
                hasError(IMPACT) ? classes.checkBoxErr : classes.checkBox,
                classes.formControlInline,
                classes.whiteBackground
              )}
              error={hasError(IMPACT)}
              required>
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage defaultMessage="Impact" id="object.IMPACT" />:
              </FormLabel>
              <FormGroup row>
                {impactList.map(impact => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vals[IMPACT].includes(impact.value)}
                        disabled={!isSenderEdit || disableEdit}
                        name={impact.value}
                        onClick={e => handleCb(e, IMPACT, vals)}
                      />
                    }
                    key={impact.value}
                    label={
                      <FormattedMessage
                        defaultMessage={impact.name}
                        id={'object.' + impact.name.toUpperCase()}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
            {hasError(IMPACT) ? (
              <FormHelperText className={classes.errorText} error>
                {formState.errors.impact[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl
              className={classNames(
                classes.checkBox,
                classes.formControlInline,
                classes.whiteBackground
              )}>
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage
                  defaultMessage="Impact"
                  id="object.SETTLEMENT"
                />
                :
              </FormLabel>
              <FormGroup row>
                {settlementList.map(settlement => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vals[SETTLEMENT].includes(settlement.value)}
                        disabled={!isSenderEdit || disableEdit}
                        name={settlement.value}
                        onClick={e => handleCb(e, SETTLEMENT, vals)}
                      />
                    }
                    key={settlement.value}
                    label={
                      <FormattedMessage
                        defaultMessage={settlement.name}
                        id={'object.' + settlement.name.toUpperCase()}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box
              alignItems="center"
              className={classNames(classes.checkBox, classes.whiteBackground)}
              display="flex"
              style={{ padding: 8 }}>
              {disableEdit || !isCreatorOrg ? null : (
                <Popup
                  button={
                    <IconButton className={classes.addItemIcon} size="small">
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                  }
                  setHasPopup={setHasPopup}>
                  <NewLinkedObject
                    existing={exclusionList}
                    isSettlement
                    objects={settlementKoe}
                    objectTypes={objectTypes}
                    onSubmit={addSettlementObjects}
                    projectId={projectId}
                    recipientOrgId={discipline_type_id}
                  />
                </Popup>
              )}
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage
                  defaultMessage="Settlement for"
                  id="object.SETTLEMENT_FOR"
                />
                :
              </FormLabel>
              {settlementKoe.map((koe, index) => {
                return (
                  <Fragment key={index}>
                    <span
                      className={classes.link}
                      onClick={e =>
                        goToLinkedObject(e, koe.id, koe.object_type.ref_name)
                      }>
                      <Typography color="secondary" variant="body1">
                        {koe.name}
                      </Typography>
                    </span>
                    {index < settlementKoe.length - 1 ? (
                      <Typography>{SEMICOLON}&nbsp;</Typography>
                    ) : (
                      ''
                    )}
                  </Fragment>
                );
              })}
            </Box>
          </Grid>
          {vals.impact.includes(IMPACT_SCHEDULE.toLowerCase()) ? (
            <Grid item md={4} xs={6}>
              <TextField
                className={classes.whiteBackground}
                disabled={!isSenderEdit || disableEdit}
                error={hasError('impact_total_schedule_days')}
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Total schedule impact (days)"
                    id="common.TOTAL_SCHEDULE_IMPACT"
                  />
                }
                name="impact_total_schedule_days"
                onChange={handleChange}
                size="small"
                type="number"
                value={impact_total_schedule_days || ''}
                variant="outlined"
              />
              {hasError('impact_total_schedule_days') ? (
                <FormHelperText className={classes.errorText} error>
                  {formState.errors.impact_total_schedule_days[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          ) : null}
          {vals.impact.includes(IMPACT_PRICE.toLowerCase()) ? (
            <Grid item md={4} xs={6}>
              <TextField
                className={classes.whiteBackground}
                disabled
                fullWidth
                inputProps={{
                  style: total_cost_nok
                    ? inputStyles.numeric
                    : inputStyles.label
                }}
                label={
                  <FormattedMessage
                    defaultMessage="Total cost (NOK)"
                    id="common.TOTAL_COST"
                  />
                }
                name="total_cost_nok"
                size="small"
                type="float"
                value={total_cost_nok || ''}
                variant="outlined"
              />
            </Grid>
          ) : null}
          {vals.impact.length > 0 ? (
            <Grid item md={12} xs={12}>
              <Typography className={classes.boldLabel}>
                <FormattedMessage
                  defaultMessage="Description of Impact"
                  id="object.DESCRIPTION_OF_IMPACT"
                />
                :
              </Typography>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={impact_description || ''}
                displayObjectView={displayObjectView}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                isReloading={isReloading}
                key={`impact_description_${id}`}
                mentionedObjs={formState.mentionedObjs}
                mentionedUsers={formState.mentionedUsers}
                name="impact_description"
                objectTypes={objectTypes}
                projectId={projectId}
                readOnly={!isSenderEdit || disableEdit}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
            </Grid>
          ) : null}
          {vals.impact.includes(IMPACT_PRICE.toLowerCase()) ? (
            <Grid item md={12} xs={12}>
              {!isSenderEdit || disableEdit ? (
                <CostTableView data={impact_price_description} />
              ) : (
                <CostTable
                  clearTimer={clearTimer}
                  impact_price_description={impact_price_description}
                  setFormState={setFormState}
                  total_cost_nok={total_cost_nok}
                  valuesField={valuesField}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <Divider className={classes.dividerMargin} />
      {showComment ? (
        <CardContent className={classes.grayContent}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
              <Typography
                align="left"
                className={classes.boldLabel}
                display="inline">
                <FormattedMessage
                  defaultMessage="{name} Comments"
                  id="object.ORG_NAME_COMMENTS"
                  values={{ name: recipientName }}
                />
                : *
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={comment}
                displayObjectView={displayObjectView}
                error={hasError('comment_text')}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                isReloading={isReloading}
                key={`comment_${id}`}
                mentionedObjs={formState.mentionedObjs}
                mentionedUsers={formState.mentionedUsers}
                name="comment"
                objectTypes={objectTypes}
                projectId={projectId}
                readOnly={isSenderEdit || disableEdit}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
              {hasError('comment_text') ? (
                <FormHelperText className={classes.errorText} error>
                  {formState.errors.comment_text[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      ) : null}
    </div>
  );
};

Eo.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleCb: PropTypes.func,
  handleChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isReloading: PropTypes.bool,
  isRevision: PropTypes.bool,
  objectTypes: PropTypes.array,
  projectId: PropTypes.string,
  recipientName: PropTypes.string,
  setFormState: PropTypes.any,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Eo;
