/* Note:  Eirik wants these to be eventually customizable.
          But since we still expect changes for KOE and EO
          with regards to design and data, it's a low priority task.
*/

export const KOE_REASON = [
  { name: 'Change', value: 'change' },
  { name: 'Force majeure', value: 'force' },
  { name: 'Breach of Client obligations', value: 'breach' }
];

const impact = [
  { name: 'Quality', value: 'quality' },
  { name: 'Schedule', value: 'schedule' },
  { name: 'Price', value: 'price' },
  { name: 'Other', value: 'other' }
];

export const EO_IMPACT = [{ name: 'HSE', value: 'hse' }, ...impact];
export const KOE_IMPACT = [{ name: 'HMS', value: 'hms' }, ...impact];

export const KOE_EO_SETTLEMENT = [
  { name: 'Unit rates', value: 'unit_rates' },
  { name: 'Adjusted unit rates', value: 'adjusted_unit_rates' },
  { name: 'Agreed price incl overhead', value: 'agreed_price' },
  { name: 'Reimbursable', value: 'reimbursable' },
  { name: 'Lump Sum', value: 'lump_sum' }
];
