import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Fullscreen,
  FullscreenExit,
  GetApp as DownloadIcon
} from '@material-ui/icons';
import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Iframe, PreviewPane } from 'components';
import ForgeViewer from 'views/Object/components/ForgeViewer/ForgeViewer';
import clsx from 'clsx';
import {
  getFileExt,
  getFilenameWithoutID,
  requestFullScreen,
  splitFileUrl
} from 'common/helper';
import { processDownload } from 'views/Object/components/helper';
import { useStyles } from '../styles';
import {
  AWS_URL,
  FILE_PATH,
  FORGE_URN,
  MS_VIEWER_URL_FORMAT,
  PDF,
  PORTRAIT,
  SHARECAD_VIEWER_URL_FORMAT
} from 'common/constants';

const DWG_FILES = process.env.REACT_APP_SUPPORTED_CAD_FILES;
const IMG_FILES = process.env.REACT_APP_SUPPORTED_IMG_FILES;
const MS_FILES = process.env.REACT_APP_SUPPORTED_MS_FILES;
const THREE_D_FILES = process.env.REACT_APP_SUPPORTED_3D_FILES;
const VIDEO_FILES = process.env.REACT_APP_SUPPORTED_VIDEO_FILES;

const FORGE_BUCKET_KEY = process.env.REACT_APP_FORGE_BUCKET_KEY;

const Slide = props => {
  const { file, index, isFullscreen, setDownload, toggleDrawer } = props;
  const classes = useStyles();
  const {
    screen: { isMobileDevice, screenOrientation }
  } = useSelector(state => state);

  const filePath = file.file;
  const [, fileName] = splitFileUrl(filePath);
  const fileType = getFileExt(fileName);
  const displayName = getFilenameWithoutID(fileName);
  const isFsOrMd = isFullscreen || isMobileDevice;

  const actionBtns = isMobileDevice ? (
    <Button
      className={classes.slideCloseBtn}
      color="primary"
      onClick={() => toggleDrawer()}
      size="small"
      variant="contained">
      <FormattedMessage defaultMessage="Close" id="common.CLOSE" />
    </Button>
  ) : (
    <>
      <Tooltip
        title={
          <FormattedMessage
            defaultMessage="Full Screen"
            id="common.FULL_SCREEN"
          />
        }>
        <IconButton
          aria-label="Full Screen"
          className={classes.displayActionBtn}
          onClick={() => requestFullScreen()}
          style={{ left: 12 }}>
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      </Tooltip>
      {fileType !== PDF && (
        <Tooltip
          title={
            <FormattedMessage defaultMessage="Download" id="common.DOWNLOAD" />
          }>
          <IconButton
            aria-label="Download"
            className={classes.displayActionBtn}
            onClick={() => processDownload(file, setDownload)}
            style={{ left: 48 }}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const isDwg = DWG_FILES.includes(fileType);
  const mobileStyle = isMobileDevice
    ? {
        height: window.screen.availHeight,
        width: window.screen.availWidth
      }
    : null;
  let mainDivProps = {
    key: `attachment_${index}`,
    slideno: index,
    style: mobileStyle
  };
  let iFrameProps = { filePath: filePath, style: mobileStyle };
  let sourceFile = FILE_PATH;
  let viewer;
  switch (true) {
    case isDwg ||
      MS_FILES.includes(fileType) ||
      (fileType === PDF && isMobileDevice):
      iFrameProps.encodeUrl = true;
      sourceFile = `${
        isDwg ? SHARECAD_VIEWER_URL_FORMAT : MS_VIEWER_URL_FORMAT
      }${FILE_PATH}`;
    // eslint-disable-next-line no-fallthrough
    case fileType === PDF:
      viewer = (
        <PreviewPane {...iFrameProps} srcFormat={sourceFile}>
          <Iframe
            filetype={fileType}
            height={isFsOrMd ? '95%' : '560'}
            title={displayName}
            width={isMobileDevice ? '95%' : null}
          />
        </PreviewPane>
      );
      break;
    case THREE_D_FILES.includes(fileType): {
      const path = filePath.replace(`${AWS_URL}/`, '');
      const urn = new Buffer.from(
        `${FORGE_URN}${FORGE_BUCKET_KEY}/${encodeURIComponent(
          decodeURIComponent(path)
        )}`
      ).toString('base64');
      return (
        <div {...mainDivProps}>
          <div className={classes.displayBlock}>
            {actionBtns}
            <Typography className={classes.viewerLabel} variant="h5">
              {displayName}
            </Typography>
          </div>
          <ForgeViewer isFullscreen={isFsOrMd} urn={urn} />
        </div>
      );
    }
    case IMG_FILES.includes(fileType):
      viewer = (
        <PreviewPane {...iFrameProps} srcFormat={sourceFile}>
          <img
            alt={fileName}
            className={
              isMobileDevice
                ? screenOrientation === PORTRAIT
                  ? classes.fullWidth
                  : classes.fullHeight
                : clsx(classes.imgPlaceholder, {
                    [classes.imgPlaceholderFull]: isFullscreen
                  })
            }
          />
        </PreviewPane>
      );
      break;
    case VIDEO_FILES.includes(fileType): {
      return (
        <PreviewPane {...iFrameProps} srcFormat={sourceFile}>
          <video
            id="video-player"
            className={
              isMobileDevice
                ? screenOrientation === PORTRAIT
                  ? classes.fullWidth
                  : classes.fullHeight
                : clsx(classes.videoPlayer, {
                    [classes.videoPlayerFull]: isFullscreen
                  })
            }
            controls>
            <source type={`video/${fileType}`} />
            Your browser does not support the video tag.
          </video>
        </PreviewPane>
      );
    }
    default:
      return (
        <div className={classes.unsupportedBackground} {...mainDivProps}>
          {actionBtns}
          <div>
            <Typography variant="h3">
              <FormattedMessage
                defaultMessage="Unsupported file type!"
                id="object.UNSUPPORTED_FILE_TYPE"
              />
            </Typography>
            <img
              alt="File not supported!"
              className={classes.unsupportedImage}
              src="/images/unsupported_file.png"
            />
            <Typography color="textPrimary" variant="h5">
              {displayName}
            </Typography>
          </div>
        </div>
      );
  }

  return (
    <div className={classes.viewerContainer} {...mainDivProps}>
      {actionBtns}
      <Typography className={classes.viewerLabel} variant="h5">
        {displayName}
      </Typography>
      {viewer}
    </div>
  );
};

Slide.propTypes = {
  file: PropTypes.object,
  index: PropTypes.number,
  isFullscreen: PropTypes.bool,
  setDownload: PropTypes.func,
  toggleDrawer: PropTypes.func
};

Slide.defaultProps = { index: 0 };

export default Slide;
