import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import { Category, Meeting } from '../';
import { useStyles } from '../../../styles';
import {
  ADMIN_TEMPLATES_OPTIONS_LIST,
  CATEGORY_OPTION,
  MEETING_OPTION,
  PAGE_TEMPLATES,
  SS_SELECTED_ADMIN_PAGE
} from 'common/constants';
import { getKeysFromSearchParams } from 'common/helper';

const Templates = ({ history, organizationId }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [view, setView] = useState({ value: null, page: null });

  const handleSelection = (_event, value) => {
    let selected,
      orion_page = '';
    switch (value) {
      case CATEGORY_OPTION:
        orion_page = 'categories';
        selected = <Category organizationId={organizationId} />;
        break;
      case MEETING_OPTION:
        orion_page = 'meetings';
        selected = <Meeting organizationId={organizationId} />;
        break;
      default:
        break;
    }
    sessionStorage.setItem(
      SS_SELECTED_ADMIN_PAGE,
      PAGE_TEMPLATES.concat(`/${orion_page}`)
    );
    setView({ value, page: selected });
  };

  useEffect(() => {
    if (history.location.search) {
      const page = getKeysFromSearchParams(history.location.search, 'subpage');
      const selectionValue =
        page.subpage === 'categories'
          ? CATEGORY_OPTION
          : page.subpage === 'meetings'
          ? MEETING_OPTION
          : '';
      if (selectionValue) handleSelection(null, selectionValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  return (
    <>
      <Autocomplete
        className={classes.padding2}
        getOptionLabel={option =>
          intl.formatMessage({
            defaultMessage: option,
            id: 'common.' + option.toUpperCase()
          })
        }
        onChange={handleSelection}
        options={ADMIN_TEMPLATES_OPTIONS_LIST}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...params.inputProps,
              'data-testid': 'autocomplete-template'
            }}
            label={
              <FormattedMessage
                defaultMessage="Select Template Type"
                id="admin.SELECT_TEMPLATE_TYPE"
              />
            }
            size="small"
            variant="outlined"
          />
        )}
        size="small"
        value={view.value}
      />
      {view.page}
    </>
  );
};

Templates.propTypes = {
  history: PropTypes.object,
  organizationId: PropTypes.string
};

export default Templates;
