import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import {
  PAGE_ARCHIVED_PROJECTS,
  PAGE_DISCIPLINES,
  PAGE_EXPORTS,
  PAGE_INFO,
  PAGE_NOTIFICATIONS,
  PAGE_PROJECTS,
  PAGE_TEMPLATES,
  PAGE_USERS,
  PAGE_USER_DETAILS
} from 'common/constants';
import { adminSetOrgSettings, projectUpdate } from '../../../redux';
import {
  ArchivedProjects,
  DisciplineTypes,
  Exports,
  Info,
  Notification,
  Project,
  Templates,
  Users,
  UserDetails
} from './components';
import { useStyles } from '../styles';

const OrgSettings = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    admin: { isOrgAdmin, selectedOrg, subPage },
    organization,
    user: { info: userInfo, notifications, setting }
  } = useSelector(state => state);
  const onClickArchive = val => {
    const { projects } = selectedOrg;
    const confirm = window.confirm(
      intl.formatMessage({
        id: val.archived ? 'admin.UNARCHIVE_MESSAGE' : 'admin.ARCHIVE_MESSAGE'
      })
    );

    if (confirm) {
      dispatch(projectUpdate(val.id, { archived: !val.archived }));
      dispatch(
        adminSetOrgSettings({
          selectedOrg: {
            ...selectedOrg,
            projects: projects.map(project =>
              project.id === val.id
                ? {
                    ...project,
                    archived: !val.archived
                  }
                : project
            )
          }
        })
      );

      return true;
    }
  };
  const getSettingView = () => {
    const opts = {
      [PAGE_ARCHIVED_PROJECTS]: (
        <ArchivedProjects
          onClickArchive={onClickArchive}
          organization={selectedOrg}
        />
      ),
      [PAGE_DISCIPLINES]: <DisciplineTypes organizationId={selectedOrg?.id} />,
      [PAGE_EXPORTS]: <Exports />,
      [PAGE_INFO]: (
        <Info
          data={selectedOrg}
          isAdmin={isOrgAdmin}
          organization={organization}
        />
      ),
      [PAGE_NOTIFICATIONS]: (
        <Notification notifications={notifications} user={userInfo} />
      ),
      [PAGE_PROJECTS]: (
        <Project onClickArchive={onClickArchive} organization={selectedOrg} />
      ),
      [PAGE_TEMPLATES]: (
        <Templates history={history} organizationId={selectedOrg?.id} />
      ),
      [PAGE_USERS]: <Users history={history} organization={selectedOrg} />,
      [PAGE_USER_DETAILS]: <UserDetails history={history} setting={setting} />
    };

    return opts[subPage || PAGE_INFO];
  };

  // commenting this for now since we'll be supporting multiple orgs per user in the future
  // const handleSelectChange = (event, org) => {
  //   setState(state => ({
  //     ...state,
  //     selectedOrg: org,
  //     organization: {
  //       ...organization,
  //       updated: null
  //     }
  //   }));
  // };

  return (
    <>
      {
        // commenting this for now since we'll be supporting multiple orgs per user in the future
        // <div>
        //   <Autocomplete
        //     getOptionLabel={option => option.name || ''}
        //     onChange={(event, org) => handleSelectChange(event, org)}
        //     options={adminOrganizations}
        //     renderInput={params => (
        //       <TextField
        //         {...params}
        //         fullWidth
        //         label="Please Select Organization"
        //         size="small"
        //         variant="outlined"
        //       />
        //     )}
        //     size="small"
        //     value={state.selectedOrg}
        //   />
        // </div>
      }
      {selectedOrg?.name || subPage === PAGE_USER_DETAILS ? (
        <Paper data-testid="container-admin" height="100%">
          <div className={classes.settingsView}>{getSettingView()}</div>
        </Paper>
      ) : null}
    </>
  );
};

OrgSettings.propTypes = {
  history: PropTypes.object
};

export default OrgSettings;
