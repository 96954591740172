import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { WysWyg } from '../../../components';
import { formatForEditor } from 'views/Object/components/helper';
import { useStyles } from '../../../styles';
import { OBJECT_BHM } from 'common/constants';
import { useSelector } from 'react-redux';

const Ta = props => {
  const {
    createdBy,
    disciplineName,
    displayObjectView,
    objectInfo: { metadata = {}, name, object_type, title }
  } = props;
  const classes = useStyles();

  const {
    project: { projectSelected }
  } = useSelector(state => state);

  const showReference = useMemo(
    () => !!projectSelected?.settings?.bhm_ta_reference_field,
    [projectSelected]
  );

  const inquiry = metadata.inquiry ?? '';
  const reference = formatForEditor(metadata.reference ?? '');
  const response = metadata.response ?? '';
  const solutions = metadata.solutions ?? '';

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {name} {title}
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Created by"
              id="common.CREATED_BY"
            />
            : {createdBy}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Action Owner"
              id="object.ACTION_OWNER"
            />
            : {metadata?.action_owner?.email || ''}
          </div>
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Discipline"
              id="common.DISCIPLINE"
            />
            : {disciplineName}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Answer Deadline"
              id="object.ANSWER_DEADLINE"
            />
            : {metadata.due_date}
          </div>
        </Box>
      </Typography>
      {showReference ? (
        <>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Reference"
                id="object.REFERENCE3"
              />
              :
            </Box>
          </Typography>
          <WysWyg
            defaultValue={reference}
            displayObjectView={displayObjectView}
            key="reference"
            name="reference"
            readOnly
          />
        </>
      ) : null}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage defaultMessage="Query" id="object.QUERY" />:
        </Box>
      </Typography>
      <WysWyg
        defaultValue={inquiry}
        displayObjectView={displayObjectView}
        key="inquiry"
        name="inquiry"
        readOnly
      />
      {object_type && object_type.ref_name === OBJECT_BHM ? null : (
        <>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Solutions"
                id="object.SOLUTIONS"
              />
              :
            </Box>
          </Typography>
          <WysWyg
            defaultValue={solutions}
            displayObjectView={displayObjectView}
            key="solutions"
            name="solutions"
            readOnly
          />
        </>
      )}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage defaultMessage="Response" id="object.RESPONSE" />:
        </Box>
      </Typography>
      <WysWyg
        defaultValue={response}
        displayObjectView={displayObjectView}
        key="response"
        name="response"
        readOnly
      />
    </div>
  );
};

Ta.propTypes = {
  createdBy: PropTypes.string,
  disciplineName: PropTypes.string,
  displayObjectView: PropTypes.func,
  isSenderOrg: PropTypes.bool,
  objectInfo: PropTypes.object
};

export default Ta;
