import Moment from 'moment';
import validate from 'validate.js';
import { getStatusById, getStatusIdByName } from '../components/helper';
import { valueInArray } from 'common/helper';
import {
  bhmSchema,
  documentApproverSchema,
  documentAttachmentSchema,
  documentCategoryNameSchema,
  documentCategorySchema,
  documentOtherSchema,
  documentSchema,
  eoSchema,
  eoCommentSchema,
  eoDescriptionSchema,
  getAgendaTitleSchema,
  getDelmalebrevSchema,
  getRefSchema,
  icoSchema,
  koeSchema,
  koeCommentSchema,
  koeDescriptionSchema,
  leSchema,
  recipientSchema,
  refSchema,
  refMeetingDateFormatSchema,
  taDateSchema,
  taMinDateSchema,
  taResponseSchema,
  taSchema,
  titleSchema
} from './schema';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  NUMBER_OF_UNITS,
  OBJECT_BHM,
  OBJECT_EO,
  OBJECT_KOE,
  OBJECT_REF,
  OBJECT_TA,
  STATUS,
  STATUS_APPROVED,
  STATUS_APPROVED_AND_CLOSED,
  STATUS_APPROVED_ESTIMATE_DISPUTED,
  STATUS_BOQ_REQUESTED,
  STATUS_CLAIM_SENT,
  STATUS_DRAFT,
  STATUS_ESTIMATE_SUBMITTED,
  STATUS_FINAL_QUANTITIES_ISSUED,
  STATUS_INFO_REQUESTED,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_NOTIFIED,
  STATUS_PRELIM_BOQ_APPROVED,
  STATUS_READY_FOR_APPROVAL,
  STATUS_READY_FOR_ISSUE,
  STATUS_REJECTED,
  STATUS_RESPONSE_ISSUED,
  STATUS_SENT,
  STATUS_SIGNED_AND_CLOSED,
  STATUS_UPDATED_QUANTITIES_ISSUED,
  STATUS_VOID
} from 'common/constants';

/* Added to set dynamic property keys*/
const schemaList = {
  bhmSchema,
  eoSchema,
  icoSchema,
  koeSchema,
  leSchema,
  refSchema,
  taSchema
};

validate.extend(validate.validators.datetime, {
  parse: function(value, _options) {
    return +Moment.utc(value, DEFAULT_DATE_FORMAT, true).valueOf();
  },
  format: function(value, options) {
    const format = options.dateOnly
      ? DEFAULT_DATE_FORMAT
      : DEFAULT_DATE_TIME_FORMAT;
    return Moment.utc(value).format(format);
  }
});

const getSchema = (statusList, values, refName) => {
  try {
    if (values.status) {
      const { status, status_id, history } = values;
      const prevStat = history && history.length > 1 && history[1].status.name;
      const currStatusName = getStatusById(statusList, status_id).name;
      let statusNameArr = [];
      switch (refName) {
        case OBJECT_BHM:
        case OBJECT_TA:
          if (
            // first Response should be required
            status.name === STATUS_SENT &&
            currStatusName === STATUS_RESPONSE_ISSUED &&
            (!prevStat || prevStat !== STATUS_RESPONSE_ISSUED)
          )
            return taResponseSchema;
          else if (
            // additional inputs are no longer required
            status.name !== STATUS_DRAFT &&
            status.name !== STATUS_READY_FOR_ISSUE
          )
            return {};
          break;
        case OBJECT_KOE:
          statusNameArr = [
            STATUS_APPROVED,
            STATUS_APPROVED_ESTIMATE_DISPUTED,
            STATUS_INFO_REQUESTED,
            STATUS_REJECTED
          ];
          if (status.name === STATUS_DRAFT) {
            return { ...koeSchema, ...koeDescriptionSchema };
          } else if (
            valueInArray([STATUS_CLAIM_SENT, STATUS_NOTIFIED], status.name) &&
            valueInArray(statusNameArr, currStatusName) &&
            (!prevStat || !valueInArray(statusNameArr, prevStat))
          ) {
            return { ...koeSchema, ...koeCommentSchema };
          }
          break;
        case OBJECT_EO:
          statusNameArr = [
            STATUS_ESTIMATE_SUBMITTED,
            STATUS_INFO_REQUESTED,
            STATUS_SIGNED_AND_CLOSED
          ];
          if (
            status.name === STATUS_DRAFT ||
            status.name === STATUS_READY_FOR_APPROVAL
          )
            return { ...eoSchema, ...eoDescriptionSchema };
          else if (
            status.name === STATUS_SENT &&
            valueInArray(statusNameArr, currStatusName) &&
            (!prevStat || !valueInArray(statusNameArr, prevStat))
          )
            return { ...eoSchema, ...eoCommentSchema };
          break;
        default:
          break;
      }
    }
    return schemaList[`${refName.toLowerCase()}Schema`];
  } catch (e) {
    return {};
  }
};

export const validateDocObject = (statusList, values) => {
  const draftStatusId = getStatusIdByName(statusList, STATUS_DRAFT);
  const voidStatusId = getStatusIdByName(statusList, STATUS_VOID);
  const { discipline_type_id, metadata, status_id } = values;

  let errors = {
    ...validate(values, documentSchema),
    ...validate(metadata, documentCategorySchema)
  };

  if (
    status_id &&
    draftStatusId &&
    voidStatusId &&
    status_id !== draftStatusId &&
    status_id !== voidStatusId
  ) {
    errors = {
      ...errors,
      ...validate(values, documentAttachmentSchema),
      ...validate(metadata, documentOtherSchema)
    };

    const forApprovalStatusId = getStatusIdByName(
      statusList,
      STATUS_ISSUED_FOR_APPROVAL
    );
    if (status_id === forApprovalStatusId || discipline_type_id)
      errors = { ...errors, ...validate(values, documentApproverSchema) };
  }
  return errors;
};

export const validateExecObject = (
  statusList,
  { isRevision, refValidations, revisionValues, values },
  refName
) => {
  const draftStatusId = getStatusIdByName(statusList, STATUS_DRAFT);
  const voidStatusId = getStatusIdByName(statusList, STATUS_VOID);
  const vals = isRevision ? revisionValues : values;
  const { agenda, status, status_id, metadata } = vals;

  let errors = {
    ...validate(
      { ...vals, title: vals.title?.trim() },
      {
        ...titleSchema,
        ...(refName === OBJECT_REF ? {} : recipientSchema)
      }
    )
  };

  if (valueInArray([OBJECT_BHM, OBJECT_TA], refName) && metadata.due_date) {
    let dateSchema = { ...taDateSchema };

    const forIssueStatusId = getStatusIdByName(
      statusList,
      STATUS_READY_FOR_ISSUE
    );
    const sentStatusId = getStatusIdByName(statusList, STATUS_SENT);
    if (
      status_id === sentStatusId && // next status
      valueInArray([draftStatusId, forIssueStatusId], status?.id) // curr status
    )
      dateSchema = { ...dateSchema, ...taMinDateSchema };

    errors = { ...errors, ...validate(metadata, { ...dateSchema }) };
  }

  if (refName === OBJECT_REF) {
    const agendaTitleSchema = getAgendaTitleSchema(agenda);
    errors = { ...errors, ...validate(refValidations, agendaTitleSchema) };

    if (metadata.meeting_date)
      errors = { ...errors, ...validate(metadata, refMeetingDateFormatSchema) };
  }

  // all Communication objects have draft status so wait until it is loaded
  // to avoid "flickering" of error message
  if (
    status_id &&
    draftStatusId &&
    status_id !== draftStatusId &&
    status_id !== voidStatusId
  ) {
    const schema = getSchema(statusList, vals, refName);
    errors = { ...errors, ...validate(metadata, schema) };

    if (refName === OBJECT_REF) {
      const refSchema = getRefSchema(agenda);
      errors = {
        ...errors,
        ...validate(refValidations, refSchema)
      };
    }
  }

  return errors;
};

export const validateBoQObject = (statusList, values) => {
  const { metadata = {}, status_id } = values;
  const status = getStatusById(statusList, status_id)?.name;

  if (valueInArray([STATUS_BOQ_REQUESTED, STATUS_DRAFT], status)) return {};
  else {
    let schema = {};
    const { delmalebrev = [] } = metadata;
    const boqValidations = delmalebrev.reduce((acc, dm, idx) => {
      return {
        ...acc,
        [`${NUMBER_OF_UNITS}_${idx}`]: dm.number_of_units,
        [`${STATUS}_${idx}`]: dm.status
      };
    }, {});

    if (
      valueInArray(
        [STATUS_FINAL_QUANTITIES_ISSUED, STATUS_UPDATED_QUANTITIES_ISSUED],
        status
      )
    )
      schema = getDelmalebrevSchema(NUMBER_OF_UNITS, delmalebrev.length);
    else if (
      valueInArray(
        [
          STATUS_APPROVED_AND_CLOSED,
          STATUS_PRELIM_BOQ_APPROVED,
          STATUS_REJECTED
        ],
        status
      )
    )
      schema = getDelmalebrevSchema(STATUS, delmalebrev.length);

    return validate(boqValidations, schema);
  }
};

export const validateDocumentCategory = (category, exclusion = []) =>
  validate(category, {
    name: {
      ...documentCategoryNameSchema.name,
      exclusion: exclusion
    }
  }) || {};
