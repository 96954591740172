import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import validate from 'validate.js';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { HorizontalTextDivider } from 'components';
import {
  DEFAULT_MEETING_NO,
  DISCIPLINE_TYPE_ID,
  DOCUMENTATION,
  LATEST_MOM_NUMBER_VERSION,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME,
  MENTION,
  OBJECT_BHM,
  OBJECT_EO,
  OBJECT_ICO,
  OBJECT_KOE,
  OBJECT_LE,
  OBJECT_REF,
  OBJECT_TA,
  OBJECT_TMPL,
  ORGANIZATION_MEETING_TEMPLATE,
  STATUS_DRAFT,
  TEMPLATE,
  TITLE
} from 'common/constants';
import {
  hasError,
  isSmallView,
  serializeParams,
  valueInArray
} from 'common/helper';
import {
  filterObjTypesList,
  getCurrentOrgType,
  getDefaultObjectTitle,
  getNewAgendaFromTemplate,
  getObjectName,
  getRecipientList,
  getStatusIdByName,
  getTopAccessLevel,
  getUserDisciplines
} from '../helper';
import {
  clearMeetingCheck,
  meetingCreationCheck,
  objectCreateOrUpdate,
  objectsFetch
} from 'redux/object/ObjectAction';
import { orgMeetingTemplatesFetch } from 'redux/organization/OrganizationAction';
import { recipientSchema, titleSchema } from '../../validations/schema';
import { useStyles } from '../../styles';

const NewObject = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    category,
    handleParentFormState,
    history,
    objectTypes,
    projectId,
    selectedObjType
  } = props;
  const {
    object,
    object: {
      disciplineTypes,
      meetingChecked,
      meetingCheckError,
      meetingSeriesCount,
      objectMentionCreated,
      objectSelected,
      statusAll,
      templates
    },
    organization: { organizationTemplates },
    project: { projectSelected },
    screen: { screenWidth },
    user
  } = useSelector(state => state);

  const initialState = {
    disciplines: [],
    errors: {},
    secondLabel: '',
    submitted: false,
    useTemplate: false,
    values: { project_id: projectId, title: '' }
  };
  const [formState, setFormState] = useState(initialState);
  const [isGlobalTemplatesOpen, setGlobalTemplatesOpen] = useState(false);

  const {
    disciplines,
    errors,
    secondLabel,
    submitted,
    useTemplate,
    values,
    values: {
      discipline_type_id,
      object_type,
      selectedTemplate,
      templateId,
      title
    }
  } = formState;
  const isMeeting = object_type?.ref_name === OBJECT_REF;
  const isMention = !!handleParentFormState;
  const projectOrganizations = projectSelected?.organizations || [];
  const currentOrgType = getCurrentOrgType(projectOrganizations);
  const topAccessLevel = getTopAccessLevel(projectSelected.roles);
  const filteredObjTypes = filterObjTypesList(
    objectTypes,
    topAccessLevel,
    currentOrgType
  );

  let templateOptions;
  if (isMeeting) {
    const projTemplates = templates?.content || [];
    const projTemplateFromOrg = projTemplates.reduce((acc, tmpl) => {
      if (tmpl.metadata.global_template_id)
        acc.push(tmpl.metadata.global_template_id);

      return acc;
    }, []);

    const orgTemplates = organizationTemplates.reduce((acc, tmpl) => {
      return !projTemplateFromOrg.includes(tmpl.id)
        ? acc.concat({ ...tmpl, source: ORGANIZATION_MEETING_TEMPLATE })
        : acc;
    }, []);

    templateOptions = orgTemplates.concat(projTemplates);
  }

  useEffect(() => {
    if (object_type) {
      let secondLabel = '';
      let disciplines = [];
      const { ref_name } = object_type;

      switch (ref_name) {
        case OBJECT_ICO:
          secondLabel = (
            <>
              <FormattedMessage
                defaultMessage="Select Discipline"
                id="common.SELECT_DISCIPLINE"
              />
              :
            </>
          );
          disciplines = getUserDisciplines(
            projectOrganizations,
            user,
            disciplineTypes[localStorage.getItem(LS_ORGANIZATION_NAME)]
          );
          break;
        case OBJECT_LE:
          secondLabel = (
            <>
              <FormattedMessage
                defaultMessage="Recipient"
                id="common.RECIPIENT"
              />
              :
            </>
          );
          disciplines = projectOrganizations;
          break;
        case OBJECT_EO:
        case OBJECT_KOE:
        case OBJECT_TA:
        case OBJECT_BHM:
          secondLabel = (
            <>
              <FormattedMessage
                defaultMessage="Recipient"
                id="common.RECIPIENT"
              />
              :
            </>
          );
          disciplines = getRecipientList(
            ref_name,
            projectOrganizations,
            currentOrgType
          );
          break;
        case OBJECT_REF:
          disciplines = projectOrganizations;
          // fetch latest templates
          dispatch(
            orgMeetingTemplatesFetch(localStorage.getItem(LS_ORGANIZATION_ID))
          );
          dispatch(
            objectsFetch(projectId, serializeParams({ type: TEMPLATE }))
          );
          break;
        default:
          break;
      }

      setFormState(formState => ({
        ...formState,
        secondLabel,
        disciplines
      }));
    } else {
      if (selectedObjType)
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            object_type: selectedObjType,
            object_type_id: selectedObjType.id
          }
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object_type, selectedObjType]);

  useEffect(() => {
    if (!isMention && objectSelected.id) {
      history.push(`/object/${objectSelected.id}?project_id=${projectId}`, {
        projectId: projectId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectSelected]);

  useEffect(() => {
    if (objectMentionCreated.id && submitted)
      if (handleParentFormState) {
        handleParentFormState({
          ...values,
          id: objectMentionCreated.id
        });
        setFormState(initialState);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectMentionCreated, handleParentFormState, initialState]);

  useEffect(() => {
    const errors = validate(
      { ...values, title: values.title?.trim() },
      { ...recipientSchema, ...titleSchema }
    );
    setFormState(formState => ({
      ...formState,
      errors: errors || {}
    }));
  }, [values]);

  useEffect(() => {
    if (meetingChecked) handleAddObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingChecked]);

  const handleDeselectTemplate = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        agenda: null,
        metadata: {},
        name: '',
        selectedTemplate: null,
        templateId: null,
        title: ''
      }
    }));
    dispatch(clearMeetingCheck());
  };

  const handleChange = (event, obj, isTemplate = false) => {
    event.persist();

    let newVals;
    if (isTemplate) {
      if (!obj || obj.source === ORGANIZATION_MEETING_TEMPLATE)
        newVals = {
          agenda: null,
          metadata: {
            ...(projectSelected?.settings?.mom_number_version ===
            LATEST_MOM_NUMBER_VERSION
              ? {
                  meeting_no: DEFAULT_MEETING_NO,
                  previous_meeting_item_count: 0
                }
              : {})
          },
          name: '',
          selectedTemplate: obj,
          templateId: obj?.id,
          title: obj?.title || getDefaultObjectTitle(OBJECT_TMPL)
        };
      else
        newVals = {
          selectedTemplate: obj,
          agenda: obj.agenda.map(agendum => getNewAgendaFromTemplate(agendum)),
          metadata: {
            global_template_id: obj.metadata.global_template_id,
            template_id: obj.id,
            previous_meeting_item_count:
              obj.metadata.previous_meeting_item_count
          },
          name: obj.name,
          templateId: obj.id,
          title: obj.title
        };

      dispatch(clearMeetingCheck());
    } else if (obj) {
      newVals = { object_type: obj, object_type_id: obj.id };
    } else {
      newVals = { [event.target.name]: event.target.value };
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ...newVals
      }
    }));
  };

  const handleGlobalTemplatesToggle = () => {
    setGlobalTemplatesOpen(!isGlobalTemplatesOpen);
  };

  const getRevisionValue = (refName, revision) => {
    if (valueInArray([OBJECT_ICO, OBJECT_LE, OBJECT_REF], refName)) return;
    return revision ?? 0;
  };

  const handleAddObject = event => {
    if (event) event.preventDefault();

    if (values?.selectedTemplate?.source === ORGANIZATION_MEETING_TEMPLATE) {
      // creation of project template and object are being handled in ObjectInfo
      history.push('/object/new', {
        objectType: object_type,
        disciplineTypeId: discipline_type_id,
        projectId: projectId,
        templateId: templateId,
        selectedTemplate
      });
    } else if (isMeeting && useTemplate && templateId && !meetingChecked) {
      dispatch(
        meetingCreationCheck(
          serializeParams({ project_id: projectId, template_id: templateId })
        )
      );
    } else {
      let create = isMeeting && meetingChecked ? !meetingCheckError : true;

      if (create) {
        // set name
        const refName = object_type.ref_name;
        values.name = getObjectName(
          object,
          projectOrganizations,
          refName,
          discipline_type_id
        );
        values.title = values.title || getDefaultObjectTitle(refName);
        values.status_id = getStatusIdByName(statusAll, STATUS_DRAFT); // set status to Draft
        values.metadata = {
          ...values.metadata,
          ...(projectSelected?.settings?.mom_number_version ===
          LATEST_MOM_NUMBER_VERSION
            ? {
                meeting_no: useTemplate
                  ? meetingSeriesCount + 1
                  : DEFAULT_MEETING_NO
              }
            : {}),
          created_by_org_id: localStorage.getItem(LS_ORGANIZATION_ID),
          revision: getRevisionValue(refName, values.metadata?.revision)
        };

        switch (refName) {
          case OBJECT_ICO:
            break; // do nothing
          case OBJECT_REF:
            delete values.discipline_type_id; // remove recipient
            break;
          default:
            // set organization_id
            values.organization_id = discipline_type_id;
            delete values.discipline_type_id;
            break;
        }

        if (handleParentFormState) {
          dispatch(objectCreateOrUpdate(values, MENTION));
          setFormState(formState => ({
            ...formState,
            values: values,
            submitted: true
          }));
        } else {
          dispatch(objectCreateOrUpdate(values));
        }
        dispatch(clearMeetingCheck());
      }
    }
  };

  return (
    <div className={classes.componentPopupRoot}>
      <form>
        <Typography variant="h3">
          {category === DOCUMENTATION ? (
            <FormattedMessage
              defaultMessage="Create new document"
              id="object.DOCUMENT_NEW"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Add object"
              id="object.ADD_OBJECT"
            />
          )}
        </Typography>
        <Divider className={classes.popupDivider} />
        <Grid container spacing={isSmallView(screenWidth) ? 0 : 2}>
          <Grid item md={4} xs={12}>
            <Typography>
              {category === DOCUMENTATION ? (
                <FormattedMessage
                  defaultMessage="Select Document Type"
                  id="common.SELECT_DOCUMENT_TYPE"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Select Object Type"
                  id="common.SELECT_OBJECT_TYPE"
                />
              )}
              : *
            </Typography>
            <List>
              {filteredObjTypes.map(o => (
                <ListItem
                  button
                  className={classes.buttonList}
                  id={`item-${o.ref_name}`}
                  key={o.name}
                  onClick={event => handleChange(event, o)}
                  selected={object_type?.id === o.id}>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        defaultMessage={o.name}
                        id={'object.' + o.name.toUpperCase()}
                      />
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                {isMeeting ? (
                  <>
                    {meetingCheckError ? (
                      <Alert severity="error">{meetingCheckError}</Alert>
                    ) : null}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useTemplate}
                          onClick={() => {
                            if (useTemplate) handleDeselectTemplate(); // when unchecked
                            setFormState(formState => ({
                              ...formState,
                              useTemplate: !formState.useTemplate
                            }));
                          }}
                        />
                      }
                      label={
                        <FormattedMessage
                          defaultMessage="Meeting series"
                          id="common.MEETING_SERIES"
                        />
                      }
                    />
                    {useTemplate ? (
                      <Autocomplete
                        getOptionLabel={option => option.title || ''}
                        groupBy={option => option.source}
                        id={TEMPLATE}
                        name={TEMPLATE}
                        onChange={(e, temp) => handleChange(e, temp, true)}
                        options={templateOptions}
                        renderGroup={({ key, children }) =>
                          key === ORGANIZATION_MEETING_TEMPLATE
                            ? [
                                <ListItem
                                  key={`group-label-${key}`}
                                  button
                                  onClick={handleGlobalTemplatesToggle}>
                                  <ListItemIcon
                                    className={classes.filterItemIcon}>
                                    {isGlobalTemplatesOpen ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <FormattedMessage
                                        defaultMessage="New series from template"
                                        id="common.NEW_SERIES"
                                      />
                                    }
                                  />
                                </ListItem>,
                                <Collapse
                                  key={`group-${key}`}
                                  in={isGlobalTemplatesOpen}
                                  timeout="auto">
                                  {children}
                                </Collapse>
                              ]
                            : [
                                <HorizontalTextDivider
                                  key={`group-${key}-divider`}>
                                  <FormattedMessage
                                    defaultMessage="Ongoing meeting series"
                                    id="common.ONGOING_SERIES"
                                  />
                                </HorizontalTextDivider>,
                                children
                              ]
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label=<FormattedMessage
                              defaultMessage="Meeting series"
                              id="common.MEETING_SERIES"
                            />
                            size="small"
                            variant="outlined"
                          />
                        )}
                        size="small"
                        value={selectedTemplate || {}}
                      />
                    ) : null}
                  </>
                ) : (
                  <TextField
                    error={hasError(errors, DISCIPLINE_TYPE_ID)}
                    fullWidth
                    helperText={
                      hasError(errors, DISCIPLINE_TYPE_ID)
                        ? errors.discipline_type_id[0]
                        : null
                    }
                    label={secondLabel}
                    name={DISCIPLINE_TYPE_ID}
                    onChange={handleChange}
                    required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    style={{ display: object_type?.id ? 'block' : 'none' }}
                    value={discipline_type_id || ''}
                    variant="outlined">
                    <option disabled key="defaultValue" value="" />
                    {disciplines.map(option => (
                      <option key={option.name} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                )}
              </Grid>
              {isMention ? (
                <Grid item md={12} xs={12}>
                  <TextField
                    error={hasError(errors, TITLE)}
                    fullWidth
                    helperText={
                      hasError(errors, TITLE) ? errors.title[0] : null
                    }
                    label={
                      <FormattedMessage
                        defaultMessage="Title"
                        id="common.TITLE"
                      />
                    }
                    name={TITLE}
                    onChange={handleChange}
                    required
                    type="text"
                    value={title || ''}
                    variant="outlined"
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.popupDivider} />
        <Button
          className={classes.floatRight}
          color="primary"
          disabled={
            !object_type ||
            (!isMeeting && !discipline_type_id) ||
            (isMention && hasError(errors, TITLE)) ||
            !!meetingCheckError
          }
          id="create-new-btn"
          onClick={handleAddObject}
          type="submit"
          variant="contained">
          <FormattedMessage
            defaultMessage="Create New"
            id="common.CREATE_NEW"
          />
        </Button>
      </form>
    </div>
  );
};

NewObject.propTypes = {
  category: PropTypes.string,
  handleParentFormState: PropTypes.func,
  history: PropTypes.object,
  objectTypes: PropTypes.array,
  projectId: PropTypes.string,
  selectedObjType: PropTypes.object
};

NewObject.defaultProps = { objectTypes: [] };

export default NewObject;
