import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { CostTable, WysWyg } from '../../components';
import { CostTable as CostTableView } from '../../ObjectView/components';
import {
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import {
  KOE_IMPACT as impactList,
  KOE_REASON as reasonList,
  KOE_EO_SETTLEMENT as settlementList
} from '../data';
import { inputStyles, useStyles } from '../../styles';
import {
  REVISION_VALUES,
  STATUS_APPROVED,
  STATUS_APPROVED_ESTIMATE_DISPUTED,
  STATUS_CLAIM_SENT,
  STATUS_DRAFT,
  STATUS_INFO_REQUESTED,
  STATUS_NOTIFIED,
  STATUS_REJECTED,
  VALUES
} from 'common/constants';
import { valueInArray } from 'common/helper';

const Koe = props => {
  const {
    clearTimer,
    createdBy,
    disableEdit,
    displayObjectView,
    formState,
    handleCb,
    handleChange,
    handleEditorChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    isReloading,
    isRevision,
    objectTypes,
    projectId,
    recipientName,
    setFormState,
    setHasPopup,
    suggestions
  } = props;
  const classes = useStyles();

  const valuesField = useMemo(() => (isRevision ? REVISION_VALUES : VALUES), [
    isRevision
  ]);
  const {
    id,
    metadata: {
      comment,
      description,
      impact = [],
      impact_price_description,
      impact_total_schedule_days,
      reason = [],
      settlement = [],
      total_cost_nok
    },
    status
  } = formState[valuesField];
  const vals = {
    reason: [...reason],
    impact: [...impact],
    settlement: [...settlement]
  };

  const statusName = useMemo(() => status?.name || STATUS_DRAFT, [status]);
  const isSenderEdit = useMemo(() => {
    return (
      valueInArray(
        [
          STATUS_DRAFT,
          STATUS_CLAIM_SENT,
          STATUS_NOTIFIED,
          STATUS_INFO_REQUESTED,
          STATUS_REJECTED,
          STATUS_APPROVED_ESTIMATE_DISPUTED,
          STATUS_APPROVED
        ],
        statusName
      ) && isCreatorOrg
    );
  }, [isCreatorOrg, statusName]);

  const showComment = useMemo(
    () => !(statusName === STATUS_DRAFT && formState.selectedRevision <= 0),
    [formState.selectedRevision, statusName]
  );

  return (
    <div>
      <Divider />
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl
              className={classNames(
                hasError('reason') ? classes.checkBoxErr : classes.checkBox,
                classes.formControlInline,
                classes.whiteBackground
              )}
              error={hasError('reason')}
              required>
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage
                  defaultMessage="Reason for change"
                  id="object.REASON_FOR_CHANGE"
                />
                :
              </FormLabel>
              <FormGroup row>
                {reasonList.map(val => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vals.reason.includes(val.value)}
                        disabled={!isSenderEdit || disableEdit}
                        name={val.value}
                        onClick={e => handleCb(e, 'reason', vals)}
                      />
                    }
                    key={val.value}
                    label={
                      <FormattedMessage
                        defaultMessage={val.name}
                        id={'object.' + val.name.toUpperCase()}
                      />
                    }
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
            {hasError('reason') ? (
              <FormHelperText className={classes.errorText} error>
                {formState.errors.reason[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Created by"
                  id="common.CREATED_BY"
                />
              }
              size="small"
              value={createdBy || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
            <Typography className={classes.boldLabel}>
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
              : *
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <WysWyg
              clearTimer={clearTimer}
              defaultValue={description}
              displayObjectView={displayObjectView}
              error={hasError('description_text')}
              handleMentionItems={handleMentionItems}
              handleParentEditorChange={handleEditorChange}
              isReloading={isReloading}
              key={`description_${id}`}
              mentionedObjs={formState.mentionedObjs}
              mentionedUsers={formState.mentionedUsers}
              name="description"
              objectTypes={objectTypes}
              projectId={projectId}
              readOnly={!isSenderEdit || disableEdit}
              setHasPopup={setHasPopup}
              suggestions={suggestions}
            />
            {hasError('description_text') ? (
              <FormHelperText className={classes.errorText} error>
                {formState.errors.description_text[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              className={classNames(
                hasError('impact') ? classes.checkBoxErr : classes.checkBox,
                classes.formControlInline,
                classes.whiteBackground
              )}
              error={hasError('impact')}
              required>
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage defaultMessage="Impact" id="object.IMPACT" />:
              </FormLabel>
              <FormGroup row>
                {impactList.map(val => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vals.impact.includes(val.value)}
                        disabled={!isSenderEdit || disableEdit}
                        name={val.value}
                        onClick={e => handleCb(e, 'impact', vals)}
                      />
                    }
                    key={val.value}
                    label={
                      <FormattedMessage
                        defaultMessage={val.value}
                        id={'object.' + val.value.toUpperCase()}
                      />
                    }
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
            {hasError('impact') ? (
              <FormHelperText className={classes.errorText} error>
                {formState.errors.impact[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item md={8} xs={12}>
            <FormControl
              className={classNames(
                classes.checkBox,
                classes.formControlInline,
                classes.whiteBackground
              )}>
              <FormLabel
                className={classNames(
                  classes.boldLabel,
                  classes.formLabelMargin
                )}>
                <FormattedMessage
                  defaultMessage="Settlement"
                  id="object.SETTLEMENT"
                />
                :
              </FormLabel>
              <FormGroup row>
                {settlementList.map(val => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vals.settlement.includes(val.value)}
                        disabled={!isSenderEdit || disableEdit}
                        name={val.value}
                        onClick={e => handleCb(e, 'settlement', vals)}
                      />
                    }
                    key={val.value}
                    label={
                      <FormattedMessage
                        defaultMessage={val.name}
                        id={'object.' + val.name.toUpperCase()}
                      />
                    }
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          {vals.impact.includes('schedule') ? (
            <Grid item md={4} xs={6}>
              <TextField
                className={classes.whiteBackground}
                disabled={!isSenderEdit || disableEdit}
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Total schedule impact (days)"
                    id="common.TOTAL_SCHEDULE_IMPACT"
                  />
                }
                name="impact_total_schedule_days"
                onChange={handleChange}
                size="small"
                type="number"
                value={impact_total_schedule_days || ''}
                variant="outlined"
              />
              {hasError('impact_total_schedule_days') ? (
                <FormHelperText className={classes.errorText} error>
                  {formState.errors.impact_total_schedule_days[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          ) : null}
          {vals.impact.includes('price') ? (
            <>
              <Grid item md={4} xs={6}>
                <TextField
                  className={classes.whiteBackground}
                  disabled
                  fullWidth
                  inputProps={{
                    style: total_cost_nok
                      ? inputStyles.numeric
                      : inputStyles.label
                  }}
                  label={
                    <FormattedMessage
                      defaultMessage="Total cost (NOK)"
                      id="common.TOTAL_COST"
                    />
                  }
                  name="total_cost_nok"
                  size="small"
                  type="float"
                  value={total_cost_nok || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                {isSenderEdit && !disableEdit ? (
                  <CostTable
                    clearTimer={clearTimer}
                    impact_price_description={impact_price_description}
                    setFormState={setFormState}
                    total_cost_nok={total_cost_nok}
                    valuesField={valuesField}
                  />
                ) : (
                  <CostTableView data={impact_price_description} />
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
      <Divider className={classes.dividerMargin} />
      {showComment ? (
        <CardContent className={classes.grayContent}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
              <Typography
                align="left"
                className={classes.boldLabel}
                display="inline">
                <FormattedMessage
                  defaultMessage="{name} Comments"
                  id="object.ORG_NAME_COMMENTS"
                  values={{ name: recipientName }}
                />
                : *
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={comment}
                displayObjectView={displayObjectView}
                error={hasError('comment_text')}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                isReloading={isReloading}
                key={`comment_${id}`}
                mentionedObjs={formState.mentionedObjs}
                mentionedUsers={formState.mentionedUsers}
                name="comment"
                objectTypes={objectTypes}
                projectId={projectId}
                readOnly={isSenderEdit || disableEdit}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
              {hasError('comment_text') ? (
                <FormHelperText className={classes.errorText} error>
                  {formState.errors.comment_text[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      ) : null}
    </div>
  );
};

Koe.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleCb: PropTypes.func,
  handleChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isReloading: PropTypes.bool,
  isRevision: PropTypes.bool,
  objectTypes: PropTypes.array,
  projectId: PropTypes.string,
  recipientName: PropTypes.string,
  setFormState: PropTypes.any,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Koe;
