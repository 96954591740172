import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Divider,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import NewIcon from '@material-ui/icons/FiberNew';
import LanguageSelect from 'components/LanguageSelect';
import { FormattedMessage } from 'react-intl';
import { handleRedirectToBeta } from 'common/helper';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'white'
  },
  link: {
    borderRadius: 0,
    cursor: 'pointer',
    display: 'flex',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logo: {
    width: '8rem',
    padding: '12px 120px'
  },
  menu: {
    display: 'flex',
    width: '100%'
  },
  flexGrow: {
    flexGrow: 1
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const {
    user: { error: userError }
  } = useSelector(state => state);
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      position="static">
      <Toolbar>
        <RouterLink to={userError ? '/sign-in' : '/'}>
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logos/logo--white.svg"
          />
        </RouterLink>
        <div className={classes.menu}>
          <div className={classes.flexGrow} />
          <IconButton
            className={classes.link}
            color="secondary"
            onClick={handleRedirectToBeta}>
            <NewIcon />
            {/*TODO: Improve label and/or translation */}
            <Typography color="secondary">
              <FormattedMessage
                default="Try the Beta site"
                id="main.BETA_SITE_MSG"
              />
            </Typography>
          </IconButton>
          <Divider flexItem orientation="vertical" variant="middle" />
          <LanguageSelect />
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
