import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    deleteIcon: {
      color: '#e86d6f'
    },
    popupRoot: {
      height: '100%',
      width: '550px',
      padding: theme.spacing(2)
    },
    popupDivider: {
      margin: theme.spacing(2, 0)
    },
    rootAlign: {
      textAlign: 'center'
    },
    textField: {
      marginTop: theme.spacing(2)
    },
    alert: {
      marginBottom: theme.spacing(2)
    },
    icon: {
      alignSelf: 'center',
      color: '#ff9052',
      height: 125,
      width: 125
    },
    deleteButton: {
      backgroundColor: '#e86d6f',
      color: '#fff',
      margin: theme.spacing(2),
      '&:hover': {
        backgroundColor: '#f95f62'
      },
      '&:active': {
        backgroundColor: '#e86d6f'
      }
    },
    text: {
      margin: theme.spacing(2)
    },
    highlight: {
      color: '#f00'
    },
    appBar: {
      backgroundColor: '#5a6978',
      top: 'auto',
      bottom: 0
    },
    appBarButton: {
      margin: theme.spacing(2)
    },
    appBarText: {
      color: '#fff',
      borderRight: '0.1em solid white',
      padding: theme.spacing(2)
    },
    addInviteIcon: {
      color: '#77d353'
    },
    inviteButton: {
      marginTop: theme.spacing(2),
      backgroundColor: 'transparent'
    },
    componentPopupRoot: {
      height: '100%',
      overflowY: 'auto',
      padding: theme.spacing(2),
      width: 550,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: 500
      },
      [theme.breakpoints.only('xs')]: {
        '@media (orientation: landscape)': {
          width: 480
        },
        '@media (orientation: portrait)': {
          width: 230
        }
      }
    },
    floatRight: {
      float: 'right'
    },
    attachmentPreviewList: {
      maxHeight: 380,
      overflowY: 'auto'
    },
    attachmentAlert: {
      padding: theme.spacing(0, 1)
    },
    image: {
      width: '100%'
    },
    orgChartClass: {
      background: 'none',
      border: 'none',
      padding: 'unset',
      '& > ul > li > ul li::before': {
        borderTopColor: '#dd882e'
      },
      '& > ul > li > ul li .oc-node::before': {
        borderTopColor: '#dd882e'
      },
      'ul li .oc-node:not(:only-child)::after': {
        borderTopColor: '#dd882e'
      }
    },
    orgContainerClass: {
      border: 'none',
      height: 'unset'
    },
    orgNodeContainer: {
      width: 270
    },
    orgNodeContainerNav: {
      '& > div:not(:last-child)': {
        borderBottom: '1px solid #eeeeee'
      }
    },
    orgNodeContainerDetailContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      '& > p:first-child': {
        maxWidth: 85,
        minWidth: 85
      }
    },
    orgNodeContainerDetailsTitle: {
      paddingRight: theme.spacing(1)
    },
    orgNodeContainerDetails: {
      fontWeight: 500,
      textAlign: 'right',
      hyphens: 'manual',
      WebkitHyphens: 'manual',
      wordBreak: 'break-word'
    },
    orgNodeContainerTitle: {
      textAlign: 'center'
    },
    orgNodeOwn: {
      background: theme.palette.background.cardHeader,
      '&$disabled': {
        opacity: '1 !important'
      }
    },
    orgNodePosition: {
      background: '#F4F6F8',
      '&$disabled': {
        opacity: '1 !important'
      }
    },
    disabled: {
      /* this goes with above, do not delete */
    },
    addIcon: {
      color: '#77d353'
    },
    uploadIcon: {
      color: '#dd882e'
    },
    previewPlaceholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fullHeight: {
      height: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    boldLabel: {
      fontWeight: 500
    },
    unsupportedBackground: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      background: 'white',
      height: 400
    },
    unsupportedImage: {
      marginTop: 50,
      display: 'inline-block',
      width: '120px !important',
      height: '120px !important'
    },
    imgPlaceholder: {
      width: 'auto !important',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: 600
    },
    imgPlaceholderFull: {
      maxHeight: '100%'
    },
    videoPlayer: {
      width: 'auto !important',
      height: 'auto !important',
      maxWidth: '100%',
      minHeight: 300
    },
    videoPlayerFull: {
      maxHeight: '100%'
    },
    displayBlock: {
      display: 'block'
    },
    inlineFlex: {
      display: 'inline-flex'
    },
    link: {
      cursor: 'pointer'
    },
    displayActionBtn: {
      bottom: 0,
      zIndex: 2,
      position: 'absolute',
      '&:hover': {
        backgroundColor: 'rgb(191 171 171 / 21%)'
      }
    },
    slideCloseBtn: {
      bottom: 8,
      left: 8,
      zIndex: 2,
      position: 'absolute'
    },
    viewerContainer: {
      position: 'relative',
      textAlign: 'center'
    },
    viewerLabel: {
      color: 'white',
      backgroundColor: 'black',
      position: 'absolute',
      bottom: 8,
      right: 16,
      zIndex: 2
    },
    marginBottom2: {
      marginBottom: theme.spacing(2)
    },
    boqRoot: {
      maxHeight: 'calc(100vh - 100px)',
      width: 'calc(100vw - 100px)'
    },
    boqSum: {
      height: 88
    },
    boqTableHeaderRow: {
      background: '#f4f6f8'
    },
    boqModalTableRoot: {
      maxHeight: 'calc(100vh - 295px)'
    },
    boqTableRoot: {
      maxHeight: 'calc(100vh - 325px)'
    },
    tableCellBorder: {
      border: `1px solid ${theme.palette.divider}`
    },
    grayBackground: {
      backgroundColor: '#ddd'
    },
    whiteBackground: {
      backgroundColor: theme.palette.white
    },
    tablePaginationSelect: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    searchBoxContainer: {
      alignItems: 'center',
      display: 'flex'
    },
    searchBox: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    searchDivider: {
      height: theme.spacing(2)
    }
  }),
  { index: 1 }
);
