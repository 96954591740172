import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import {
  AppBar,
  Divider,
  Hidden,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import NewIcon from '@material-ui/icons/FiberNew';
import Navigation from '../Navigation';
import { Confirm, LanguageSelect } from 'components';
import {
  DOC_WITH_ID_URL,
  LS_ORGANIZATION_ID,
  LS_STR8LINE_TOKEN,
  OBJECT_WITH_ID_URL
} from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { handleRedirectToBeta } from 'common/helper';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28)',
    backgroundColor: 'white'
  },
  flexGrow: {
    flexGrow: 1
  },
  link: {
    borderRadius: 0,
    cursor: 'pointer',
    display: 'flex',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logo: {
    width: '8rem',
    padding: '12px',
    cursor: 'pointer'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutBody: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  }
}));

const Topbar = props => {
  const { className, history, ...rest } = props;
  const classes = useStyles();
  const params = QueryString.parse(window.location.search);
  const userInfo = useSelector(state => state.user.info);
  const isLoggedIn = params.token;

  const [confirm, setConfirm] = useState(false);

  if (!userInfo.id && !localStorage.getItem(LS_STR8LINE_TOKEN) && !isLoggedIn) {
    return <Redirect to="/sign-in" />;
  }

  const navigateToPage = () => {
    setConfirm(false);
    const path = userInfo.jwt
      ? '/'
      : localStorage.getItem(LS_ORGANIZATION_ID)
      ? '/projects'
      : '/organizations';
    history.push(path);
  };
  const handleClick = () => {
    if (
      history.location.pathname.includes(OBJECT_WITH_ID_URL) ||
      history.location.pathname.includes(DOC_WITH_ID_URL)
    )
      setConfirm(true);
    else navigateToPage();
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      position="sticky">
      <Toolbar>
        {confirm ? (
          <Confirm
            abortLabel={
              <FormattedMessage
                default="Stay on this page"
                id="object.STAY_ON_THIS_PAGE"
              />
            }
            confirmLabel={
              <FormattedMessage
                default="Leave this page"
                id="object.LEAVE_THIS_PAGE"
              />
            }
            message={
              <Typography>
                <FormattedMessage
                  default="You will lose any unsaved changes if you navigate away from the page."
                  id="object.NAVIGATE_UNSAVED_CHANGES"
                />
              </Typography>
            }
            onClose={() => setConfirm(null)}
            onConfirm={navigateToPage}
          />
        ) : null}
        <div onClick={handleClick}>
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logos/logo--white.svg"
          />
        </div>
        <div className={classes.logoutBody}>
          <div className={classes.flexGrow} />
          <IconButton
            className={classes.link}
            color="secondary"
            onClick={handleRedirectToBeta}>
            <NewIcon />
            {/*TODO: Improve label and/or translation */}
            <Typography color="secondary">
              <FormattedMessage
                default="Try the Beta site"
                id="main.BETA_SITE_MSG"
              />
            </Typography>
          </IconButton>
          <Divider flexItem orientation="vertical" variant="middle" />
          <LanguageSelect />
          {isLoggedIn ? null : (
            <>
              <Divider flexItem orientation="vertical" variant="middle" />
              <Hidden mdDown>
                <Navigation history={history} userInfo={userInfo} />
              </Hidden>
              <Hidden lgUp>
                <Navigation history={history} userInfo={userInfo} />
              </Hidden>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
