import {
  OBJECT_BHM,
  OBJECT_BOQ,
  OBJECT_DOC,
  OBJECT_EO,
  OBJECT_ICO,
  OBJECT_KOE,
  OBJECT_LE,
  OBJECT_REF,
  OBJECT_TA,
  ROLE_DL,
  ROLE_PARTICIPANT,
  ROLE_PL,
  STATUS_ANSWERED_AND_CLOSED,
  STATUS_APPROVED,
  STATUS_APPROVED_AND_ACCEPTED,
  STATUS_APPROVED_AND_CLOSED,
  STATUS_APPROVED_AND_PUBLISHED,
  STATUS_APPROVED_BY_DL,
  STATUS_APPROVED_BY_PL,
  STATUS_APPROVED_ESTIMATE_DISPUTED,
  STATUS_BOQ_REQUESTED,
  STATUS_CLAIM_SENT,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_ESTIMATE_SUBMITTED,
  STATUS_EVALUATE,
  STATUS_EVALUATION_COMPLETE,
  STATUS_EVALUATION_ON_GOING,
  STATUS_FEEDBACK_GIVEN,
  STATUS_FINAL_QUANTITIES_ISSUED,
  STATUS_HOLD,
  STATUS_IMPLEMENT,
  STATUS_IMPLEMENTATION_COMPLETE,
  STATUS_IMPLEMENTATION_ON_GOING,
  STATUS_INFO_REQUESTED,
  STATUS_ISSUED,
  STATUS_ISSUED_AND_APPROVED,
  STATUS_ISSUED_FOR_REVIEW,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_ISSUED_WITH_COMMENTS,
  STATUS_NOT_AFFECTED,
  STATUS_NOT_USED,
  STATUS_NOTIFIED,
  STATUS_PRELIM_BOQ_APPROVED,
  STATUS_PUBLISHED,
  STATUS_READ,
  STATUS_READY_FOR_APPROVAL,
  STATUS_READY_FOR_ISSUE,
  STATUS_READY_TO_SEND,
  STATUS_REJECTED,
  STATUS_RESPONSE_ISSUED,
  STATUS_RETURNED_WITH_COMMENTS,
  STATUS_REVISED,
  STATUS_SENT,
  STATUS_SHARED,
  STATUS_SIGNED_AND_CLOSED,
  STATUS_UPDATED_QUANTITIES_ISSUED,
  STATUS_VOID,
  STATUS_WITHDRAWN,
  STATUS_UNSHARED
} from 'common/constants';
import { valueInArray } from 'common/helper';
import { getStatusById, getStatusByName, getStatusIdByName } from './helper';

export const getDisciplineStatus = mainStatus => {
  switch (mainStatus) {
    case STATUS_APPROVED_BY_PL:
    case STATUS_EVALUATION_ON_GOING:
    case STATUS_EVALUATION_COMPLETE:
      return STATUS_EVALUATE;
    case STATUS_IMPLEMENTATION_ON_GOING:
    case STATUS_IMPLEMENTATION_COMPLETE:
      return STATUS_IMPLEMENT;
    default:
      return STATUS_DRAFT;
  }
};

export const getMainStatusList = (
  objType,
  status,
  statusList,
  topAccessLevel,
  isCreatorOrg,
  showVoid,
  revisions,
  canApproveDoc
) => {
  let filteredStatuses = [...statusList];
  const filterVoidCheck = (status, topRank) => {
    return showVoid
      ? status.sequence_rank <= topRank
      : status.sequence_rank <= topRank && status.name !== STATUS_VOID;
  };
  switch (objType) {
    case OBJECT_EO:
      if (!status || !isSentStatus(status)) {
        const topRank = topAccessLevel === ROLE_DL ? 2 : 3;
        filteredStatuses = statusList.filter(
          v => v.name !== status && filterVoidCheck(v, topRank)
        );
      } else {
        filteredStatuses = statusList.filter(v => {
          return (
            v.name !== status &&
            (isCreatorOrg
              ? v.name === STATUS_WITHDRAWN
              : v.name !== STATUS_WITHDRAWN)
          );
        });
      }
      break;
    case OBJECT_ICO:
      if (!status || status === STATUS_DRAFT)
        filteredStatuses = statusList.filter(v => {
          return topAccessLevel === ROLE_PL
            ? v.name === STATUS_APPROVED_BY_PL
            : topAccessLevel === ROLE_DL
            ? v.name === STATUS_APPROVED_BY_DL
            : v.sequence_rank <= 2;
        });
      else if (status === STATUS_ISSUED_FOR_APPROVAL)
        filteredStatuses = statusList.filter(v => {
          if (v.sequence_rank === 8) return true;
          return topAccessLevel === ROLE_PL
            ? v.name === STATUS_APPROVED_BY_PL
            : topAccessLevel === ROLE_DL
            ? v.name === STATUS_APPROVED_BY_DL
            : false;
        });
      else if (status === STATUS_APPROVED_BY_DL)
        if (topAccessLevel === ROLE_PL)
          filteredStatuses = statusList.filter(
            v => v.name === STATUS_APPROVED_BY_PL || v.sequence_rank === 8
          );
        else filteredStatuses = [];
      else if (
        valueInArray(
          [
            STATUS_APPROVED_BY_PL,
            STATUS_EVALUATION_COMPLETE,
            STATUS_EVALUATION_ON_GOING,
            STATUS_IMPLEMENTATION_ON_GOING
          ],
          status
        )
      )
        filteredStatuses = statusList.filter(
          v => v.name !== status && v.sequence_rank >= 6
        );
      break;
    case OBJECT_KOE:
      if (!status || status === STATUS_DRAFT)
        filteredStatuses = statusList.filter(
          v => v.name !== status && filterVoidCheck(v, 2)
        );
      else if (valueInArray([STATUS_CLAIM_SENT, STATUS_NOTIFIED], status)) {
        filteredStatuses = statusList.filter(
          v =>
            v.sequence_rank === 3 &&
            (isCreatorOrg
              ? v.name === STATUS_WITHDRAWN
              : v.name !== STATUS_WITHDRAWN)
        );
      } else if (status === STATUS_APPROVED)
        filteredStatuses = statusList.filter(v => v.sequence_rank > 3);
      else
        filteredStatuses = statusList.filter(v =>
          valueInArray([STATUS_REVISED, STATUS_WITHDRAWN], v.name)
        );
      break;
    case OBJECT_LE:
      if (status !== STATUS_SENT) {
        const topRank = topAccessLevel === ROLE_PARTICIPANT ? 2 : 3;
        filteredStatuses = statusList.filter(
          v => v.name !== status && filterVoidCheck(v, topRank)
        );
      }
      break;
    case OBJECT_REF:
      if (!status || status === STATUS_DRAFT)
        filteredStatuses = statusList.filter(v => v.name === STATUS_ISSUED);
      else if (status === STATUS_ISSUED) filteredStatuses = [];
      else {
        // keep this to support other existing statuses
        const currStatus = statusList.find(s => s.name === status);
        filteredStatuses = statusList.filter(
          v =>
            v.name !== status &&
            currStatus &&
            v.sequence_rank === currStatus.sequence_rank + 1
        );
      }
      break;
    case OBJECT_BHM:
    case OBJECT_TA:
      if (!status || status === STATUS_DRAFT) {
        const topRank = topAccessLevel === ROLE_PARTICIPANT ? 2 : 3;
        filteredStatuses = statusList.filter(
          v => v.name !== status && filterVoidCheck(v, topRank)
        );
      } else if (status === STATUS_READY_FOR_ISSUE)
        filteredStatuses =
          topAccessLevel === ROLE_PARTICIPANT
            ? []
            : statusList.filter(
                v =>
                  v.name === STATUS_SENT || (showVoid && v.name === STATUS_VOID)
              );
      else if (status === STATUS_SENT)
        filteredStatuses = statusList.filter(
          v => v.name === STATUS_RESPONSE_ISSUED
        );
      else if (status === STATUS_RESPONSE_ISSUED)
        filteredStatuses = statusList.filter(v => v.name !== status);
      break;
    case OBJECT_DOC:
      if (!status || status === STATUS_DRAFT)
        filteredStatuses = statusList.filter(
          v =>
            !valueInArray(
              [STATUS_HOLD, STATUS_INFO_REQUESTED, status],
              v.name
            ) && filterVoidCheck(v, 3)
        );
      else if (status === STATUS_ISSUED_FOR_APPROVAL)
        filteredStatuses = statusList.filter(v =>
          canApproveDoc
            ? !valueInArray(
                [
                  STATUS_HOLD,
                  STATUS_ISSUED_FOR_APPROVAL,
                  STATUS_PUBLISHED,
                  STATUS_REVISED,
                  ...(isCreatorOrg ? [] : [STATUS_VOID])
                ],
                v.name
              )
            : v.name === STATUS_VOID
        );
      else if (status === STATUS_INFO_REQUESTED)
        filteredStatuses = statusList.filter(v =>
          valueInArray([STATUS_ISSUED_FOR_APPROVAL, STATUS_VOID], v.name)
        );
      else if (
        valueInArray([STATUS_PUBLISHED, STATUS_APPROVED_AND_PUBLISHED], status)
      )
        filteredStatuses = statusList.filter(v =>
          isCreatorOrg
            ? valueInArray([STATUS_HOLD, STATUS_VOID], v.name)
            : v.name === STATUS_HOLD
        );
      else if (status === STATUS_HOLD) {
        // get the status before hold/shared/unshared of current object
        let prevStatus = revisions
          .slice(-1)[0]
          ?.history.find(
            hist =>
              !valueInArray(
                [STATUS_HOLD, STATUS_SHARED, STATUS_UNSHARED],
                hist?.status?.name
              )
          );
        prevStatus = prevStatus?.status?.name || STATUS_PUBLISHED;
        filteredStatuses = statusList.filter(v =>
          isCreatorOrg
            ? valueInArray([prevStatus, STATUS_VOID], v.name)
            : v.name === prevStatus
        );
      }
      break;
    case OBJECT_BOQ:
      if (!status || status === STATUS_DRAFT) {
        filteredStatuses = isCreatorOrg
          ? statusList.filter(
              v => v.sequence_rank === 2 || v.name === STATUS_NOT_USED
            )
          : statusList.filter(v =>
              valueInArray([STATUS_BOQ_REQUESTED, STATUS_NOT_USED], v.name)
            );
      } else if (status === STATUS_BOQ_REQUESTED) {
        // get the status before BoQ requested of current object
        const prevStatus = revisions.slice(-1)[0]?.history[1]?.status?.name;
        if ((!prevStatus || prevStatus === STATUS_DRAFT) && isCreatorOrg)
          filteredStatuses = statusList.filter(
            v => v.sequence_rank === 2 || v.name === STATUS_NOT_USED
          );
        else filteredStatuses = [];
      } else if (status === STATUS_UPDATED_QUANTITIES_ISSUED)
        filteredStatuses = statusList.filter(
          v => v.sequence_rank === 3 && v.sequence_sub_rank < 4
        );
      else if (status === STATUS_FINAL_QUANTITIES_ISSUED)
        filteredStatuses = statusList.filter(
          v => v.sequence_rank === 3 && v.sequence_sub_rank > 1
        );
      else if (
        valueInArray([STATUS_PRELIM_BOQ_APPROVED, STATUS_REJECTED], status)
      )
        filteredStatuses = isCreatorOrg
          ? []
          : statusList.filter(v => v.name === STATUS_BOQ_REQUESTED);
      else filteredStatuses = [];
      break;
    default:
      filteredStatuses = [];
      break;
  }
  return filteredStatuses;
};

export const getDiscStatusList = (
  mainStatus,
  status,
  statusList,
  isDiscOwner,
  canSetEvaluate
) => {
  if (valueInArray([STATUS_DRAFT, STATUS_VOID], status))
    return statusList.filter(v => v.name === status);
  else if (status === STATUS_EVALUATE)
    return statusList.filter(v => v.sequence_rank === 3);
  else if (valueInArray([STATUS_FEEDBACK_GIVEN, STATUS_NOT_AFFECTED], status))
    return statusList.filter(
      v =>
        (canSetEvaluate
          ? isDiscOwner
            ? valueInArray([2, 3], v.sequence_rank)
            : v.name === STATUS_EVALUATE
          : v.sequence_rank === 3) && v.name !== status
    );
  else return statusList.filter(v => v.sequence_rank === 5);
};

export const evaluateStatus = (
  statusList,
  params,
  disciplineTypeList,
  statusAllList
) => {
  // if params.main_status is true and discipline_status is true,
  // it means both were updated; skip additional checking for now (TBD).
  // if params.main_status and discipline_status are both false,
  // it means there was no change; no need to update.

  // only main status is updated
  if (params.main_status && !params.discipline_status) {
    let newDiscStatus = '';
    const mainStatus = getStatusById(statusList, params.status_id);
    const mainStatusName = mainStatus ? mainStatus.name : '';
    switch (mainStatusName) {
      case STATUS_DRAFT:
        newDiscStatus = STATUS_DRAFT;
        break;
      case STATUS_APPROVED_BY_PL:
        newDiscStatus = STATUS_EVALUATE;
        break;
      case STATUS_IMPLEMENTATION_ON_GOING:
        newDiscStatus = STATUS_IMPLEMENT;
        break;
      case STATUS_IMPLEMENTATION_COMPLETE:
        newDiscStatus = STATUS_COMPLETED;
        break;
      case STATUS_VOID:
        newDiscStatus = STATUS_VOID;
        break;
      default:
        break;
    }
    if (newDiscStatus) {
      const newStatusId = getStatusIdByName(statusAllList, newDiscStatus);
      newStatusId &&
        params.metadata &&
        params.metadata.disciplines &&
        Object.keys(params.metadata.disciplines).forEach(org => {
          params.metadata.disciplines[org].forEach(disc => {
            if (disc.status !== newDiscStatus) {
              disc.status = newDiscStatus;
              const disciplineType = disciplineTypeList[org].filter(
                d => d.name === disc.name
              )[0];
              const updatedDiscs = params.updated_disciplines
                ? [...params.updated_disciplines]
                : [];
              params.updated_disciplines = [
                ...updatedDiscs,
                {
                  discipline_type_id: disciplineType?.id,
                  status_id: newStatusId
                }
              ];
            }
          });
        });
    }
  } else if (params.discipline_status && !params.main_status) {
    // only discipline is updated
    const objDisciplines = params.metadata.disciplines;
    const objKeys = Object.keys(objDisciplines);
    let notDraft = false,
      evalIncomplete = false,
      implIncomplete = false;

    objKeys.forEach(key => {
      objDisciplines[key].forEach(disc => {
        notDraft = notDraft || disc.status !== STATUS_DRAFT;
        evalIncomplete =
          evalIncomplete ||
          (disc.status !== STATUS_FEEDBACK_GIVEN &&
            disc.status !== STATUS_NOT_AFFECTED);
        implIncomplete = implIncomplete || disc.status !== STATUS_COMPLETED;
      });
    });

    if (notDraft) {
      let statusName = STATUS_IMPLEMENTATION_COMPLETE;

      if (implIncomplete) {
        if (evalIncomplete) statusName = STATUS_EVALUATION_ON_GOING;
        else statusName = STATUS_EVALUATION_COMPLETE;
      }

      const statusId = statusName && getStatusIdByName(statusList, statusName);

      params.status_id = statusId || params.status_id;
    }
  }

  return params;
};

export const isCompletedStatus = (status, objType) => {
  switch (status) {
    case STATUS_IMPLEMENTATION_COMPLETE: // ICO
    case STATUS_READ: // Letter
    case STATUS_APPROVED_AND_ACCEPTED: // KOE
    case STATUS_WITHDRAWN:
    case STATUS_SIGNED_AND_CLOSED: // EO
    case STATUS_ANSWERED_AND_CLOSED: // TA
    case STATUS_ISSUED_AND_APPROVED: // REF
    case STATUS_ISSUED_WITH_COMMENTS:
    case STATUS_APPROVED_AND_CLOSED: // BOQ
    case STATUS_VOID: // All
      return true;
    case STATUS_ISSUED:
      if (objType && objType === OBJECT_REF) return true; // Meeting
      return false;
    default:
      return false;
  }
};

export const isDisabledStatus = (
  status,
  mainStatus,
  objType,
  isDisciplineType,
  isCreatorOrg,
  canSetEvaluate
) => {
  if (isDisciplineType) {
    if (mainStatus === STATUS_EVALUATION_COMPLETE) return !canSetEvaluate;
    return valueInArray(
      [
        STATUS_DRAFT,
        STATUS_ISSUED_FOR_APPROVAL,
        STATUS_APPROVED_BY_DL,
        STATUS_IMPLEMENTATION_COMPLETE,
        STATUS_VOID
      ],
      mainStatus
    );
  } else {
    switch (status) {
      case STATUS_SENT:
        if (
          objType &&
          valueInArray([OBJECT_BHM, OBJECT_EO, OBJECT_KOE, OBJECT_TA], objType)
        )
          return false;
        return true;
      case STATUS_IMPLEMENTATION_COMPLETE:
      case STATUS_VOID:
        return true;
      case STATUS_APPROVED_BY_PL:
      case STATUS_EVALUATION_ON_GOING:
      case STATUS_IMPLEMENTATION_ON_GOING:
        return !isCreatorOrg;
      default:
        return false;
    }
  }
};

export const isSentStatus = status => {
  switch (status) {
    case STATUS_DRAFT:
    case STATUS_READY_FOR_ISSUE:
    case STATUS_READY_FOR_APPROVAL:
    case STATUS_READY_TO_SEND:
    case STATUS_ISSUED_FOR_REVIEW:
      return false;
    default:
      return true;
  }
};

export const isRecipient = (status, objType) => {
  switch (status) {
    case STATUS_ISSUED:
    case STATUS_ISSUED_FOR_REVIEW:
    case STATUS_REVISED:
    case STATUS_SENT:
      return true;
    case STATUS_ISSUED_FOR_APPROVAL:
      return objType && objType === OBJECT_ICO ? false : true;
    default:
      return false;
  }
};

export const nextStatusOutsideOrg = (objType, currStatus, nextStatus) => {
  if (currStatus === nextStatus) return false;

  switch (objType) {
    case OBJECT_BHM:
    case OBJECT_TA:
      if (
        (currStatus !== STATUS_SENT && nextStatus === STATUS_SENT) ||
        (currStatus === STATUS_SENT && nextStatus === STATUS_RESPONSE_ISSUED)
      )
        return true;
      break;
    case OBJECT_DOC:
      if (
        (valueInArray([STATUS_DRAFT, STATUS_INFO_REQUESTED], currStatus) &&
          nextStatus === STATUS_ISSUED_FOR_APPROVAL) ||
        (currStatus === STATUS_ISSUED_FOR_APPROVAL &&
          valueInArray(
            [STATUS_APPROVED_AND_PUBLISHED, STATUS_INFO_REQUESTED],
            nextStatus
          ))
      )
        return true;
      break;
    case OBJECT_EO:
      if (
        nextStatus === STATUS_SENT ||
        (currStatus === STATUS_SENT &&
          valueInArray(
            [
              STATUS_SIGNED_AND_CLOSED,
              STATUS_INFO_REQUESTED,
              STATUS_ESTIMATE_SUBMITTED
            ],
            nextStatus
          )) ||
        (valueInArray(
          [STATUS_INFO_REQUESTED, STATUS_ESTIMATE_SUBMITTED],
          currStatus
        ) &&
          nextStatus === STATUS_REVISED)
      )
        return true;
      break;
    case OBJECT_ICO:
      if (
        nextStatus === STATUS_APPROVED_BY_PL ||
        nextStatus === STATUS_EVALUATION_COMPLETE ||
        nextStatus === STATUS_IMPLEMENTATION_COMPLETE
      )
        return true;
      break;
    case OBJECT_KOE:
      const recvrStatusArr = [
        STATUS_INFO_REQUESTED,
        STATUS_REJECTED,
        STATUS_APPROVED_ESTIMATE_DISPUTED,
        STATUS_APPROVED
      ];
      if (
        (currStatus === STATUS_DRAFT &&
          valueInArray([STATUS_CLAIM_SENT, STATUS_NOTIFIED], nextStatus)) ||
        (valueInArray([STATUS_CLAIM_SENT, STATUS_NOTIFIED], currStatus) &&
          valueInArray(recvrStatusArr, nextStatus)) ||
        (valueInArray(recvrStatusArr, currStatus) &&
          valueInArray([STATUS_REVISED, STATUS_WITHDRAWN], nextStatus))
      )
        return true;
      break;
    case OBJECT_LE:
      if (currStatus !== STATUS_SENT && nextStatus === STATUS_SENT) return true;
      break;
    case OBJECT_REF:
      if (
        nextStatus === STATUS_ISSUED ||
        (currStatus === STATUS_DRAFT &&
          valueInArray(
            [STATUS_ISSUED_AND_APPROVED, STATUS_ISSUED_FOR_REVIEW],
            nextStatus
          )) ||
        (currStatus === STATUS_ISSUED_FOR_REVIEW &&
          nextStatus === STATUS_RETURNED_WITH_COMMENTS)
      )
        return true;
      break;
    case OBJECT_BOQ:
      return true;
    default:
      break;
  }
  return false;
};

export const getDefaultRevisionStatus = (
  isCreatorOrg,
  allStatus,
  previousSentStatus
) => {
  const status = isCreatorOrg
    ? STATUS_DRAFT
    : previousSentStatus || STATUS_SENT;

  return getStatusByName(allStatus, status);
};
