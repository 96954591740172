import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Card,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {
  AccountCircle as AccountCircleIcon,
  Archive as ArchiveIcon,
  Assignment as AssignmentIcon,
  Build as BuildIcon,
  Clear as ClearIcon,
  // Dashboard as DashboardIcon,
  FileCopy as FileCopyIcon,
  Group as GroupIcon,
  Info as InfoIcon,
  ListAlt as ListAltIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon
} from '@material-ui/icons';
import {
  getKeysFromSearchParams,
  isCurrentOrg,
  isDesktopView,
  isMobileView
} from 'common/helper';
import {
  DRAWER_LEFT_OPEN,
  DRAWER_VARIANT_PERSISTENT,
  DRAWER_VARIANT_TEMPORARY,
  // PAGE_DASHBOARD,
  PAGE_EXPORTS,
  PAGE_ARCHIVED_PROJECTS,
  PAGE_DISCIPLINES,
  PAGE_INFO,
  PAGE_NOTIFICATIONS,
  PAGE_ORGANIZATIONS,
  PAGE_PROJECTS,
  PAGE_TEMPLATES,
  PAGE_USERS,
  PAGE_USER_DETAILS,
  SS_SELECTED_ADMIN_PAGE
} from 'common/constants';
import {
  adminSetOrgMenu,
  adminSetOrgSettings,
  configurationFetch,
  objectAccessTypeFetch,
  objectTypeFetch,
  organizationFetch,
  organizationTypeFetch,
  settingFetch,
  standardFetch,
  userRolesFetch
} from '../../redux';
// import Dashboard from './Dashboard';
import OrgSettings from './OrgSettings';
import { useStyles } from './styles';

const AdminMain = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    admin: { isOrgAdmin, isProjLead, selectedOrg, subPage },
    organization,
    organization: { organizations, updated: orgUpdated },
    project: { updated: projectUpdated },
    screen: { screenWidth },
    user: { info: userInfo, notifications }
  } = useSelector(state => state);
  const isDesktop = isDesktopView(screenWidth);
  const [state, setState] = useState({
    drawerLeftOpen: true,
    orgMenu: false,
    page: PAGE_ORGANIZATIONS
  });
  const closeDrawers = () => {
    if (isMobileView(screenWidth))
      setState(state => ({
        ...state,
        drawerLeftOpen: false
      }));
  };
  const toggleDrawer = drawer => {
    setState(state => ({
      ...state,
      [drawer]: !state[drawer]
    }));
  };
  const updateViewState = (type, page) => {
    dispatch(adminSetOrgMenu(page));

    setState(state => ({
      ...state,
      ...(type === 'page' && {
        orgMenu: false,
        page
      }),
      ...(type === 'subPage' && {
        page: PAGE_ORGANIZATIONS
      }),
      ...(state.drawerLeftOpen && {
        drawerLeftOpen: false
      })
    }));

    if (type === 'subPage')
      sessionStorage.setItem(SS_SELECTED_ADMIN_PAGE, page);
  };
  const getPage = () => {
    const opts = {
      [PAGE_ORGANIZATIONS]: <OrgSettings history={history} />
    };

    return opts[state.page || PAGE_ORGANIZATIONS];
  };

  useEffect(() => {
    dispatch(organizationFetch());
    dispatch(organizationFetch('all'));
    dispatch(organizationTypeFetch());
    dispatch(userRolesFetch());
    dispatch(objectAccessTypeFetch());
    dispatch(objectTypeFetch());
    dispatch(standardFetch());
  }, [dispatch]);

  useEffect(() => {
    if (history.location.search) {
      const page = getKeysFromSearchParams(history.location.search, 'page');
      if (page.page) updateViewState('subPage', page.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    if (orgUpdated) dispatch(organizationFetch(null, orgUpdated));
  }, [dispatch, orgUpdated]);

  useEffect(() => {
    if (userInfo?.id) dispatch(configurationFetch(userInfo.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projectUpdated]);

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(configurationFetch(userInfo.id));
      dispatch(settingFetch(userInfo.id));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    const organizationInfo = organizations.find(org => isCurrentOrg(org.id));
    const newSelectedOrg = orgUpdated
      ? organizationInfo
      : // initial load; only for "one user - one org"
      // todo : remove when we support multiple orgs per user again
      //        retain only the last result
      (!selectedOrg || selectedOrg.id !== organizations[0].id) &&
        organizationInfo
      ? organizationInfo
      : selectedOrg;
    const isOrgAdmin = newSelectedOrg?.admin;
    const isProjLead = isOrgAdmin || selectedOrg?.projects?.length > 0;

    dispatch(
      adminSetOrgSettings({
        isOrgAdmin,
        isProjLead,
        selectedOrg: newSelectedOrg
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organization]);

  useEffect(() => {
    setState(state => ({
      ...state,
      drawerLeftOpen: state.drawerLeftOpen || isDesktop,
      orgMenu: !isDesktop
    }));
  }, [isDesktop, state.drawerLeftOpen]);

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={closeDrawers}
        open={state.drawerLeftOpen}
        PaperProps={{
          'data-testid': 'drawer-admin'
        }}
        variant={
          isMobileView(screenWidth)
            ? DRAWER_VARIANT_TEMPORARY
            : DRAWER_VARIANT_PERSISTENT
        }>
        <Divider />
        <Hidden lgUp>
          <Box
            className={classes.projectContainer}
            display="flex"
            justifyContent="flex-end">
            <IconButton
              onClick={() => toggleDrawer(DRAWER_LEFT_OPEN)}
              size="small">
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
        </Hidden>
        <List>
          {/*
           Commenting out the Dashboard page as this may come in use when the Change Register screen is implemented
           <ListItem
              button
              onClick={() => updateViewState('page', PAGE_DASHBOARD)}
              selected={state.page === PAGE_DASHBOARD}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Dashboard"
                    id="admin.DASHBOARD"
                  />
                }
              />
            </ListItem>
          */}
          {selectedOrg ? (
            <ListItem
              button
              data-testid="item-info"
              onClick={() => updateViewState('subPage', PAGE_INFO)}
              selected={subPage === PAGE_INFO}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Info" id="admin.INFO" />
                }
              />
            </ListItem>
          ) : null}
          <ListItem
            button
            data-testid="item-account"
            onClick={() => updateViewState('subPage', PAGE_USER_DETAILS)}
            selected={subPage === PAGE_USER_DETAILS}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage defaultMessage="Info" id="admin.ACCOUNT" />
              }
            />
          </ListItem>
          {isOrgAdmin ? (
            <>
              <ListItem
                button
                data-testid="item-users"
                onClick={() => updateViewState('subPage', PAGE_USERS)}
                selected={subPage === PAGE_USERS}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage defaultMessage="Users" id="admin.USERS" />
                  }
                />
              </ListItem>
              <ListItem
                button
                data-testid="item-disciplines"
                onClick={() => updateViewState('subPage', PAGE_DISCIPLINES)}
                selected={subPage === PAGE_DISCIPLINES}>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      defaultMessage="Disciplines"
                      id="admin.DISCIPLINES"
                    />
                  }
                />
              </ListItem>
            </>
          ) : null}
          {isProjLead ? (
            <>
              <ListItem
                button
                data-testid="item-templates"
                onClick={() => updateViewState('subPage', PAGE_TEMPLATES)}
                selected={subPage === PAGE_TEMPLATES}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      defaultMessage="Templates"
                      id="admin.TEMPLATES"
                    />
                  }
                />
              </ListItem>
              <ListItem
                button
                data-testid="item-projects"
                onClick={() => updateViewState('subPage', PAGE_PROJECTS)}
                selected={subPage === PAGE_PROJECTS}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      defaultMessage="Projects"
                      id="project.PROJECTS"
                    />
                  }
                />
              </ListItem>
              <ListItem
                button
                data-testid="item-archived-projects"
                onClick={() =>
                  updateViewState('subPage', PAGE_ARCHIVED_PROJECTS)
                }
                selected={subPage === PAGE_ARCHIVED_PROJECTS}>
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      defaultMessage="Archived Projects"
                      id="admin.ARCHIVED_PROJECTS"
                    />
                  }
                />
              </ListItem>
            </>
          ) : null}
          {notifications.length > 0 ? (
            <ListItem
              button
              data-testid="item-notifications"
              onClick={() => updateViewState('subPage', PAGE_NOTIFICATIONS)}
              selected={subPage === PAGE_NOTIFICATIONS}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Notifications"
                    id="admin.NOTIFICATIONS"
                  />
                }
              />
            </ListItem>
          ) : null}
          <ListItem
            button
            data-testid="item-exports"
            onClick={() => updateViewState('subPage', PAGE_EXPORTS)}
            selected={subPage === PAGE_EXPORTS}>
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage defaultMessage="Exports" id="admin.EXPORTS" />
              }
            />
          </ListItem>
        </List>
      </Drawer>
      <Hidden lgUp>
        <Card className={classes.menuIconContainer}>
          <IconButton
            className={classes.menuIcon}
            onClick={() => toggleDrawer(DRAWER_LEFT_OPEN)}
            size="small">
            <MenuIcon color="secondary" variant="button" />
          </IconButton>
        </Card>
      </Hidden>
      <main className={classNames(classes.main, classes.mainContent)}>
        {getPage()}
      </main>
    </>
  );
};

AdminMain.propTypes = {
  history: PropTypes.object
};

export default AdminMain;
