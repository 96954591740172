import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QueryString from 'query-string';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { loginWithToken, userInfo } from '../../redux';
import { useUserInfo, useTokenLogin } from '../../hooks';

import { Topbar } from './components';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isTokenExpired } from '../../common/helper';
import {
  LS_STR8LINE_TOKEN,
  MATCH_URL_OBJECTS,
  SET_PASSWORD
} from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100% - 84px)'
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const Main = props => {
  const {
    children,
    children: {
      props: { history, match }
    }
  } = props;

  if (history.location.pathname.includes(SET_PASSWORD)) {
    localStorage.clear();
    sessionStorage.clear();
  }

  const classes = useStyles();
  const {
    document,
    export: { loading: exportLoading },
    object,
    pdf,
    project,
    user: { deleted: userDeleted, error: userError, loading: userLoading }
  } = useSelector(state => state);
  const [state, setState] = useState({
    tokenChecked: false,
    checkAuth: false
  });
  const getPageError = () => pdf.error;
  const [hasErr, setError] = useState({ open: false, msg: getPageError() });
  const params = QueryString.parse(window.location.search);
  const objectId = match.params.id;
  const userJwt = localStorage.getItem(LS_STR8LINE_TOKEN);
  const isExpired = isTokenExpired();

  useEffect(() => {
    if (params.token) {
      setState(state => ({
        ...state,
        checkAuth: true
      }));
    } else {
      setState(state => ({
        ...state,
        tokenChecked: true
      }));
    }
  }, [params.token]);

  useEffect(() => {
    const err = getPageError();
    const { open } = hasErr;
    if (!open && err)
      setError(hE => ({
        ...hE,
        open: !hE.open,
        msg: err
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf.error]);

  window.addEventListener('storage', e => {
    if (!e.key) history.push('sign-in');
  });

  useTokenLogin(loginWithToken, params.token);
  useUserInfo(userInfo, userJwt, userDeleted);

  const handleClose = () => {
    setError(hE => ({
      ...hE,
      open: !hE.open,
      msg: ''
    }));
  };

  if (isExpired) localStorage.removeItem(LS_STR8LINE_TOKEN);

  if (isExpired || (state.tokenChecked && !state.checkAuth && !userJwt)) {
    return (
      <Redirect
        to={{
          pathname: '/sign-in',
          message: isExpired ? 'error.SESSION_TIMEOUT' : null
        }}
      />
    );
  }

  if (!state.tokenChecked && !userJwt) {
    let { pathname, search: querySearch } = window.location;

    if (params.project_id && objectId)
      querySearch = `?project_id=${params.project_id}&object_id=${objectId}`;

    if (pathname !== '/set-password')
      return <Redirect to={`/sign-in${querySearch}`} />;
  }

  if (userError && typeof userError === 'string')
    return (
      <Redirect to={{ pathname: '/not-authorized', message: userError }} />
    );

  const isLoading =
    userLoading ||
    project.loading ||
    pdf.loading ||
    document.loading ||
    exportLoading ||
    (object.loading && match.url === MATCH_URL_OBJECTS);

  return (
    <div className={classes.root}>
      <Topbar history={history} />
      <main className={classes.content}>{children}</main>
      {isLoading ? (
        <Backdrop className={classes.backdrop} open>
          <div>
            <CircularProgress color="primary" />
          </div>
        </Backdrop>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleClose}
        open={hasErr.open}>
        <Alert onClose={handleClose} severity="error">
          {hasErr.msg}
        </Alert>
      </Snackbar>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
