import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { CostTable } from '../../components';
import { WysWyg } from '../../../components';
import { useStyles } from '../../../styles';
import { EO_IMPACT, KOE_EO_SETTLEMENT } from '../../../components/data';
import * as Constants from 'common/constants';
import classNames from 'classnames';

const Eo = props => {
  const { createdBy, displayObjectView, objectInfo, recipientName } = props;
  const classes = useStyles();
  const intl = useIntl();
  const objectEo = objectInfo.metadata || {};

  const comment = objectEo.comment ?? '';
  const description = objectEo.description ?? '';
  const impact_price = objectEo.total_cost_nok ?? '';
  const impact_schedule = objectEo.impact_total_schedule_days ?? '';
  const impact_description = objectEo.impact_description ?? '';
  const impact_price_description = objectEo.impact_price_description ?? [];

  let hasPrice = false,
    hasSchedule = false,
    impacts = [],
    settlements = [];

  if (objectEo.impact && objectEo.impact.length > 0)
    impacts = EO_IMPACT.filter(ei => objectEo.impact.includes(ei.value)).map(
      i => {
        if (i.name === Constants.IMPACT_PRICE) hasPrice = true;
        if (i.name === Constants.IMPACT_SCHEDULE) hasSchedule = true;
        return intl.formatMessage({
          defaultMessage: i.name,
          id: 'object.' + i.name.toUpperCase()
        });
      }
    );

  if (objectEo.settlement && objectEo.settlement.length > 0) {
    settlements = KOE_EO_SETTLEMENT.filter(es =>
      objectEo.settlement.includes(es.value)
    ).map(s =>
      intl.formatMessage({
        defaultMessage: s.name,
        id: 'object.' + s.name.toUpperCase()
      })
    );
  }

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Created by"
            id="common.CREATED_BY"
          />
          : {createdBy}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage defaultMessage="Impact" id="object.IMPACT" />:{' '}
          {impacts.join(', ')}
        </Box>
        {hasSchedule ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              defaultMessage="Total schedule impact (days)"
              id="common.TOTAL_SCHEDULE_IMPACT"
            />
            : {impact_schedule}
          </Box>
        ) : null}
        {hasPrice ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              defaultMessage="Total cost (NOK)"
              id="common.TOTAL_COST"
            />
            : {impact_price} <br />
          </Box>
        ) : null}
        <Box
          className={classNames(classes.noMarginBottom, classes.noMarginTop)}
          m={1}
          textAlign="left">
          <FormattedMessage
            defaultMessage="Settlement"
            id="object.SETTLEMENT"
          />
          : {settlements.join(', ')}
        </Box>
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Description"
            id="common.DESCRIPTION"
          />
          :
        </Box>
      </Typography>
      <WysWyg
        defaultValue={description}
        displayObjectView={displayObjectView}
        key="description"
        name="description"
        readOnly
      />
      {impacts.length > 0 ? (
        <div>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Description of Impact"
                id="object.DESCRIPTION_OF_IMPACT"
              />
              :
            </Box>
          </Typography>
          <WysWyg
            defaultValue={impact_description}
            displayObjectView={displayObjectView}
            name="impact_description"
            readOnly
          />
        </div>
      ) : null}
      {hasPrice ? <CostTable data={impact_price_description} /> : null}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="{name} Comments"
            id="object.ORG_NAME_COMMENTS"
            values={{ name: recipientName }}
          />
          :
        </Box>
      </Typography>
      <WysWyg
        defaultValue={comment}
        displayObjectView={displayObjectView}
        key="comment"
        name="comment"
        readOnly
      />
    </div>
  );
};

Eo.propTypes = {
  createdBy: PropTypes.string,
  displayObjectView: PropTypes.func,
  isSenderOrg: PropTypes.bool,
  objectInfo: PropTypes.object,
  recipientName: PropTypes.string
};

export default Eo;
