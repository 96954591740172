import * as jwt from 'jsonwebtoken';
import Moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/en-gb';
import qs from 'qs';
import {
  ACCESS_NONE,
  ACTION_GET,
  ACTION_POST,
  AWS_URL,
  BOQ,
  BUCKET_EXPORTS,
  COMPANY_CODE_LENGTH,
  DEFAULT_ACCESS_BY_ROLE,
  DEFAULT_INDENT_MULTIPLIER,
  DOCUMENTATION,
  DOT,
  DUBHE_URL,
  EXPORT_URL,
  FORWARD_SLASH,
  HYPHEN,
  INFO,
  LANDSCAPE,
  LS_ORGANIZATION_CODE,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME,
  LS_STR8LINE_TOKEN,
  ORION_URL,
  PAGE_ARCHIVED_PROJECTS,
  PAGE_USER_DETAILS,
  PORTRAIT,
  REGEX_DASH_ALL,
  REGEX_UNDERSCORE,
  REGIONAL_INDICATOR,
  SS_FILTER_MY_ACTIONS,
  SS_FILTER_PARAMS,
  SS_FILTER_SEARCH,
  SS_FILTER_UNSHARED,
  SS_FILTERS,
  SS_PAGE_NUMBER,
  SS_SELECTED_ADMIN_PAGE,
  SS_SELECTED_BOQ,
  SS_SELECTED_CATEGORY,
  SS_SORT_ORDER,
  SS_SORT_ORDER_BY,
  SS_STORED_PROJECT_ID,
  WIDTH_LG,
  WIDTH_MD,
  WIDTH_SM,
  WIDTH_XL,
  WIDTH_XS
} from './constants';

export const getUserFromToken = () => {
  return (
    localStorage.getItem(LS_STR8LINE_TOKEN) &&
    JSON.parse(jwt.decode(localStorage.getItem(LS_STR8LINE_TOKEN)).sub).user_id
  );
};

export const formatDate = (utcDate, format, locale) => {
  if (locale) {
    Moment.locale(locale);
  } else {
    Moment.locale('nb');
  }
  const date = Moment.utc(utcDate).local();
  return date.format(format ? format : 'DD.MM.YYYY [kl] H:mm');
};

export const diffWithCurrDate = utcDate => {
  return Moment().diff(Moment.utc(utcDate), 'days');
};

export const isObjectEmpty = obj => {
  return (
    !obj || (obj.constructor === Object && Object.entries(obj).length === 0)
  );
};

export const getValue = (value, defaultValue = '') =>
  value != null ? value : defaultValue;

export const serializeParams = params => {
  return {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  };
};

export const setupOrg = org => {
  if (org) {
    localStorage.setItem(LS_ORGANIZATION_ID, org.id);
    localStorage.setItem(LS_ORGANIZATION_CODE, org.org_meta.company_code);
    localStorage.setItem(LS_ORGANIZATION_NAME, org.name);
  } else clearOrgInStorage();
};

export const clearOrgInStorage = () => {
  // clear organization information in localStorage
  localStorage.removeItem(LS_ORGANIZATION_ID);
  localStorage.removeItem(LS_ORGANIZATION_CODE);
  localStorage.removeItem(LS_ORGANIZATION_NAME);
};

export const clearObjectFilter = () => {
  sessionStorage.removeItem(SS_FILTER_PARAMS);
  sessionStorage.removeItem(SS_FILTER_SEARCH);
  sessionStorage.removeItem(SS_FILTERS);
  sessionStorage.removeItem(SS_FILTER_MY_ACTIONS);
  sessionStorage.removeItem(SS_FILTER_UNSHARED);
  sessionStorage.removeItem(SS_PAGE_NUMBER);
  sessionStorage.removeItem(SS_SORT_ORDER);
  sessionStorage.removeItem(SS_SORT_ORDER_BY);
};

export const isTokenExpired = () => {
  const token = jwt.decode(localStorage.getItem(LS_STR8LINE_TOKEN), {
    complete: true
  });
  const now = new Date();

  if (token && token.payload.exp * 1000 < now.getTime()) return true;
  return false;
};

export const doesExist = (obj, searchVal, field) => {
  return obj.some(
    item => item[field].toLowerCase() === searchVal.toLowerCase()
  );
};

export const valueInArray = (list, val) => {
  return list.some(item => item === val);
};

export const getCompanyId = org_tag => org_tag?.split(HYPHEN)[1] || '';

export const generateCompanyCode = organization => {
  let acronym = organization.name.replace(/(\w)\w*\W*/g, (_, i) =>
    i.toUpperCase()
  );
  const tag = organization.org_tag;
  const tags = [];
  const words = organization.name.replace(/[^\w]/gi, ' ');
  const opts = {
    true: () => {
      if (words.split(' ').length === 1) {
        acronym = organization.name
          .toUpperCase()
          .replace(/(?:[^LAEIOUY]|ED|[^LAEIOUY]E)$/, '')
          .replace(/^y/, '')
          .replace(/[AEIOUY]{1,2}/g, '');
      }

      for (let i = 0; i < COMPANY_CODE_LENGTH - acronym.length; i++) {
        tags.push(
          tag
            .replace(/-/g, '')
            .charAt(Math.floor(Math.random() * tag.replace(/-/g, '').length))
        );
      }

      return `${acronym}${tags.join('')}`;
    },
    false: () => acronym.slice(0, COMPANY_CODE_LENGTH)
  };

  return opts[acronym.length <= 2]();
};

export const generateCompanyInitials = name => {
  return name
    .split(' ')
    .map(n => n[0])
    .splice(0, 3)
    .join('');
};

export const countryToFlag = isoCode =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + REGIONAL_INDICATOR)
        )
    : isoCode;

export const categorizeDiscTypes = discTypes => {
  return discTypes.reduce(
    (acc, dt) => {
      if (dt.archived) acc.archived.push(dt);
      else acc.notArchived.push(dt);
      return acc;
    },
    { archived: [], notArchived: [] }
  );
};

export const getDefaultAccessValue = (accessTypes, objectTypes, role) => {
  const defAccessType = DEFAULT_ACCESS_BY_ROLE[role];
  const changeStatus = accessTypes.find(
    ({ access_type }) => access_type === defAccessType
  );

  return objectTypes.map(type => {
    return {
      name: type.ref_name,
      access: changeStatus?.access_type || ACCESS_NONE
    };
  });
};

export const hasError = (errors, field) => !!(errors && errors[field]);

export const hasArrayError = (errors, field1, field2, idx) =>
  !!(
    errors &&
    errors[field1] &&
    errors[field1][0][idx] &&
    errors[field1][0][idx][field2]
  );

export const getFileExt = file => {
  const [, filename] = splitFileUrl(file);
  const index = filename.lastIndexOf(DOT);
  return index > -1 ? filename.substr(index + 1).toLowerCase() : '';
};

export const splitFileUrl = file => {
  const index = file.lastIndexOf(FORWARD_SLASH);
  return [file.substring(0, index), file.substring(index + 1)];
};

export const getFilename = file => {
  const [, filename] = splitFileUrl(file);
  return filename.split(REGEX_UNDERSCORE);
};

export const decodeWithCheck = file => {
  try {
    return decodeURIComponent(file);
  } catch (_e) {
    return file;
  }
};

export const getFilenameWithoutExt = file =>
  decodeWithCheck(file.substr(0, file.lastIndexOf(DOT)));

export const getFilenameWithoutID = file =>
  decodeWithCheck(getFilename(file)[1]);

export const getFilenameWithoutIDEncoded = file => getFilename(file)[1];

export const downloadS3File = file => {
  const dubheUrl = process.env.REACT_APP_DUBHE_URL || DUBHE_URL;
  const fileUrl = new URL(dubheUrl);

  let newFile = '';
  if (file.includes(BUCKET_EXPORTS)) {
    newFile = file.replace(`${EXPORT_URL}/`, '');
    fileUrl.searchParams.set('bucket', BUCKET_EXPORTS);
  } else newFile = file.replace(`${AWS_URL}/`, '');

  fileUrl.searchParams.set('filePath', newFile);

  downloadFile(fileUrl);
  return;
};

export const downloadFile = (url, name, method = ACTION_GET) => {
  let tag = document.createElement('a');
  tag.download = name;
  if (method === ACTION_POST)
    tag.addEventListener('click', function(event) {
      event.preventDefault();
      document.querySelector('#downloadForm').submit();
    });
  else tag.href = url;
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
  }, 100);
};

export const isOnFullScreenMode = () =>
  document.fullscreenElement ||
  document.webkitIsFullScreen ||
  document.mozFullScreen ||
  document.msFullscreenElement;

export const requestFullScreen = () => {
  const carousel = document.getElementsByClassName('carousel')[0];
  const component = document.getElementsByClassName('carousel-root')[0];

  // check if fullscreen mode is available
  if (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  ) {
    if (isOnFullScreenMode()) {
      if (document.getElementById('full')) carousel.removeAttribute('id');
      // Close fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // FF
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        // IE11
        document.msExitFullscreen();
      }
    } else {
      carousel.setAttribute('id', 'full');
      // Do fullscreen
      if (component.requestFullscreen) {
        component.requestFullscreen();
      } else if (component.webkitRequestFullscreen) {
        component.webkitRequestFullscreen();
      } else if (component.mozRequestFullScreen) {
        component.mozRequestFullScreen();
      } else if (component.msRequestFullscreen) {
        component.msRequestFullscreen();
      }
    }
  } else alert('Your browser is not supported');
};

export const isDesktopView = width => valueInArray([WIDTH_LG, WIDTH_XL], width);
export const isMobileView = width =>
  valueInArray([WIDTH_MD, WIDTH_SM, WIDTH_XS], width);
export const isSmallView = width => valueInArray([WIDTH_SM, WIDTH_XS], width);

// https://stackoverflow.com/a/52745820
// https://css-tricks.com/touch-devices-not-judged-size/
export const isMobileDevice = () =>
  window.matchMedia('(hover: none)').matches || 'ontouchstart' in window;

export const getScreenOrientation = orientation =>
  valueInArray([0, 180], orientation) ? PORTRAIT : LANDSCAPE;

export const getIndentCount = (
  count,
  multiplier = DEFAULT_INDENT_MULTIPLIER
) => {
  let indentTimes = 0;
  count = count * multiplier;
  while (count--) ++indentTimes;
  return DEFAULT_INDENT_MULTIPLIER * indentTimes;
};

const getNestedCategories = (cat, count, exclude_id) => {
  let parent = [{ ...cat, indent_count: getIndentCount(count) }];
  if (cat.children) {
    const children = cat.children.reduce((acc, child) => {
      if (child.id !== exclude_id) {
        return [...acc, ...getNestedCategories(child, count + 1, exclude_id)];
      } else return acc;
    }, []);
    parent = [...parent, ...children];
  }
  return parent;
};

export const formatCategories = (categories, exclude_id) => {
  return categories.reduce((acc, cat) => {
    if (cat.id !== exclude_id) {
      const nc = getNestedCategories(cat, 0, exclude_id);
      return [...acc, ...nc];
    } else return acc;
  }, []);
};

export const nullifyCategoryIds = category => {
  category.id = null;
  category.ancestry_id = null;
  category.document_category_template_id = null;
  category.children = category.children.map(child => nullifyCategoryIds(child));
  return category;
};

export const formatCurrency = (number, locale) => {
  const float = parseFloat(number);

  return float
    ? float.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : 0;
};

export const isCurrentOrg = id =>
  id === localStorage.getItem(LS_ORGANIZATION_ID);

export const paginationDropdown = (total, limit) =>
  Array.from(Array(Math.ceil(total / limit)).keys());

// based on https://stackoverflow.com/a/18650828
export const formatBytes = bytes => {
  if (!+bytes) return '0B';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))}${sizes[i]}`;
};

export const formatExportRequestId = id => {
  return id ? id.replace(REGEX_DASH_ALL, '').toUpperCase() : '';
};

export const handleRedirectToBeta = () => {
  let redirectUrlPath = '';
  const { pathname, search } = window.location;
  const projId = sessionStorage.getItem(SS_STORED_PROJECT_ID);
  const orgId = localStorage.getItem(LS_ORGANIZATION_ID);

  if (pathname.includes('/sign-in') || pathname.includes('/forgot')) {
    redirectUrlPath = pathname;
  } else if (pathname.includes('/set-password')) {
    redirectUrlPath = pathname.concat(search);
  } else if (pathname.includes('/admin')) {
    let selectedPage = sessionStorage.getItem(SS_SELECTED_ADMIN_PAGE) || INFO;
    switch (selectedPage) {
      case PAGE_USER_DETAILS:
        selectedPage = 'account';
        break;
      case PAGE_ARCHIVED_PROJECTS:
        selectedPage = 'archived';
        break;
      default:
    }
    redirectUrlPath = `/admin/${selectedPage}`;
  } else if (!orgId) {
    redirectUrlPath = '/organizations/create';
  } else if (!projId) {
    redirectUrlPath = '/projects';
  } else {
    const objId =
      pathname.includes('/object/') || pathname.includes('/doc/')
        ? pathname.slice(-36)
        : '';

    if (objId) {
      redirectUrlPath = `/object/${objId}`;
    } else {
      let orion_category;
      const category = sessionStorage.getItem(SS_SELECTED_CATEGORY);
      switch (category) {
        case BOQ: {
          const selectedBoq = JSON.parse(
            sessionStorage.getItem(SS_SELECTED_BOQ)
          );

          const boqOrgId =
            selectedBoq.client_organization_id === orgId
              ? selectedBoq.contractor_organization_id
              : selectedBoq.client_organization_id;

          orion_category = `boq/${boqOrgId}`;
          break;
        }
        case DOCUMENTATION:
          orion_category = 'documentation';
          break;
        default:
          orion_category = 'communication';
      }

      redirectUrlPath = `/projects/${projId}/${orion_category}`;
    }
  }

  window.location.assign(ORION_URL.concat(redirectUrlPath));
};

// accepts string or array
export const getKeysFromSearchParams = (search, keys) => {
  if (!keys) return {};

  // string is converted to array
  keys = [].concat(keys);

  const params = new URLSearchParams(search);
  let result = {};
  for (const key of keys) {
    result[key] = params.get(key);
  }
  return result;
};
